import { Table } from 'react-bootstrap';

/**
 * Displays a preview of data in a responsive table.
 *
 * @param {Object} props The component props.
 * @param {Array} props.entity The array of data to display.
 * @returns {JSX.Element} The JSX element representing the data preview table.
 */
const DataPreview = ({entity}) => {
    // Extracting table headers from the first record's attributes.
    const tableHeaders = entity.length > 0 ? Object.keys(entity[0]) : [];

    return (
        <Table responsive className="mt-sm-2 fixed-table-scroll">
            <thead>
            <tr>
                {tableHeaders.map((header) => (
                    <th key={header}>{header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {entity.map((record, index) => (
                <tr key={index}>
                    {tableHeaders.map((header) => (
                        <td key={`${index}-${header}`}>{record[header]}</td>
                        ))}
                    </tr>
            ))}
            </tbody>
        </Table>
    );
}

export default DataPreview;