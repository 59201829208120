import { useState } from 'react';
import { Form } from 'react-bootstrap';

import CustomInput from './CustomInput';

/**
 * A form dynamically rendered based on the provided parameters.
 *
 * @param {array} parameters The array of parameters of this form.
 * @param onParametersChange
 * @returns {JSX.Element} The corresponding form.
 */
const DynamicForm = ({ parameters, onParametersChange }) => {
    const [parameterValues, setParameterValues] = useState(parameters);
    const onValueChange = (value) => {
        const newParametersValue = parameterValues.map(param =>
            ({...param, value})
        );

        setParameterValues(newParametersValue);
        onParametersChange(newParametersValue);
    };

    const parametersInput = parameters.map((parameter) => (
        <CustomInput
            key={parameter.name}
            name={parameter.name}
            type={parameter.type}
            value={parameter?.value}
            onValueChange={event => onValueChange(event.target.value)}
        />
    ));

    return <Form>{parametersInput}</Form>;
};

export default DynamicForm;