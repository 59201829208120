import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
    Background,
    ConnectionMode,
    Controls,
    MiniMap,
    ReactFlowProvider,
    useEdgesState,
    useNodesState
} from 'reactflow';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import EntityNode from './Entity';
import NodeLayouter from './Layouters/NodeLayouter';
import ModalLinkDetails from "../Link/ModalLinkDetails";
import { useDataSelectorContext } from '../DataSelector/DataSelectorContext';
import FormRelationships from '../FormRelationships/FormRelationships';
import AutoRelationshipsMenu from '../FormRelationships/AutoRelationshipsMenu';
import SimpleFloatingEdge from './Layouters/SimpleFloatingEdges';
import ContextMenu from './ContextMenu';

import 'reactflow/dist/style.css';
import './DataModel.css';

const nodeTypes = {entityNode: EntityNode};
const edgeTypes = {floating: SimpleFloatingEdge};

/**
 * This method get all information about data model1 and data model2, and display all nodes, with correct layout.
 *
 * @returns {JSX.Element} All nodes with correct layout.
 */
function DataModelsGraph() {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    const {
        selectedDatasetName,
        selectedDataModelNameLeft,
        selectedDataModelNameRight,
    } = useDataSelectorContext();
    const [nodesIds, setNodesIds] = useState([]);
    const [edgesIds, setEdgesIds] = useState([]);
    const [show, setShow] = useState(false);
    const [data, setData] = useState({});
    const [retAtt, setRetAtt] = useState({});
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [selectedLink, setSelectedLink] = useState(null);
    const [showModal, setShowModal] = useState(false);

    /**
     * Handles the click event on an edge (link) in the graph.
     *
     * @param {Object} event The click event object.
     * @param {Object} element The clicked edge element.
     */

    const onEdgeClick = (event, element) => {
        if (element.id.startsWith("link") && element.links){
            setSelectedLink(element.links);
            setShowModal(true);
        }
    }

    /**
     * this function is used to get the nodeID linked to the attributeID
     * @param idAttribute the id of the attribute we want to get the node of
     * @param newNodes list of currents nodes
     * @returns {*|number} the id of the node, or -1 if no node is linked to the attribute
     */
    function getFromAttributeId(idAttribute, newNodes) {
        const matchingNode = newNodes.find(node => node.data.attrsId.includes(idAttribute));
        return matchingNode ? matchingNode.id : -1;
    }

    const getFromAttributeIdConst = useCallback((idAtt, newNodes) => getFromAttributeId(idAtt, newNodes), []);

    const onConnect = useCallback(
        params => {
            const entitySrc = nodes.filter(node => node.id === params.source)[0].entity;
            const entityDst = nodes.filter(node => node.id === params.target)[0].entity;

            if (entitySrc.dataModelName !== entityDst.dataModelName) {
                fetch("/api/researches", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        'leftEntityId': entitySrc.id,
                        'rightEntityId': entityDst.id
                    })
                })
                    .then(response => {
                        if (response.status !== 201) {
                            throw new Error("Failed to create the research");
                        }
                        return response.json();
                    })
                    .then(research => {
                        navigate(`/research/${research.id}/config`);
                    })
                    .catch(() => setShowAlert(true));
                return;
            }
            setShow(true);
            setData({"entity1": entitySrc, "entity2": entityDst});
        }, [setShow, nodes, navigate]);


    useEffect(() => {
        setEdges([])
        setEdgesIds([])
        setNodesIds([])
        setNodes([])
    }, [selectedDatasetName, setEdges, setNodes, setNodesIds, setEdgesIds]);

    useEffect(() => {
        let ignore = false;
        let jsonEntityLeft = {entities: [], relationships: []};
        let jsonEntityRight = {entities: [], relationships: []};
        let links = {links:[]}
        const linesToDraw = new Map();
        /**
         * Fetch data models values.
         *
         * @returns {Promise<void>} data from specific dataset and data model.
         */
        const fetchDataModelValues = async () => {
            if (ignore) {
                return;
            }
            const responseLeft = await fetch(`/api/datasets/${selectedDatasetName}/datamodels/${selectedDataModelNameLeft}`);
            jsonEntityLeft = await responseLeft.json();

            // Check if the selected data model name 2 is available before fetch
            if (selectedDataModelNameRight !== null) {
                const responseRight = await fetch(`/api/datasets/${selectedDatasetName}/datamodels/${selectedDataModelNameRight}`);
                jsonEntityRight = await responseRight.json();

                const responseRelationships = await fetch(`api/links?dataset=${selectedDatasetName}&dataModelsNames=${selectedDataModelNameLeft}&dataModelsNames=${selectedDataModelNameRight}`)
                links = {links : await responseRelationships.json()};

                // Fetch details for each research
                const researchesDetails = await Promise.all(links.links.map(async link => {
                    const researchDetail = await fetch(`/api/researches/${link.researchId}`);
                    return researchDetail.json();
                }));

                const idsNodes = [];

                researchesDetails.forEach(value => {
                    const lEntity = value.leftEntityName;
                    const rEntity = value.rightEntityName;
                    const pair1 = `${lEntity}|${rEntity}`;
                    const pair2 = `${rEntity}|${lEntity}`;
                    const currLink = links.links.filter(lnk => lnk.researchId === value.id)[0];

                    if (idsNodes.indexOf(pair1) !== -1){
                        linesToDraw.get(pair1).links.set(currLink.id,[currLink,value])
                        return;
                    } else if (idsNodes.indexOf(pair2) !== -1){
                        linesToDraw.get(pair2).links.set(currLink.id,[currLink,value])
                        return;
                    }
                    currLink.researche = value;
                    currLink.links = new Map();
                    currLink.links.set(currLink.id, [currLink,value])
                    linesToDraw.set(pair1, currLink)
                    idsNodes.push(`${lEntity}|${rEntity}`);

                })
            }



            const newNodes = [...jsonEntityLeft.entities.map((entity) => {
                return {
                    id: `node-${entity.name}`,
                    type: 'entityNode',
                    position: {x: 0, y: 0},
                    data: {
                        title: entity.name,
                        attrs: entity.attributes.map(attr => attr.name),
                        attrsId: entity.attributes.map(attr => attr.id),
                        entityId: entity.id
                    },
                    layoutOptions: {
                        'partitioning.partition': 1
                    },
                    entity: entity.valueOf(),
                    style: {backgroundColor: 'rgb(231,101,101)'}
                };
            }),
                ...jsonEntityRight.entities.map((entity) => {
                    return {
                        id: `node-2-${entity.name}`,
                        type: 'entityNode',
                        position: {x: 0, y: 0},
                        data: {
                            title: entity.name,
                            attrs: entity.attributes.map(attr => attr.name),
                            attrsId: entity.attributes.map(attr => attr.id),
                            entityId: entity.id
                        },
                        layoutOptions: {
                            'partitioning.partition': 2
                        },
                        entity: entity.valueOf(),
                        style: {backgroundColor: 'rgb(101,193,231)'}
                    };
                })];
            setNodes(newNodes);
            setNodesIds(newNodes.map(value => value.id));

            const seenEdges = new Set();
            const newEdges = [...jsonEntityLeft.relationships
                .map((relationship) => {
                    // Get the IDs of the entities involved in the relationship
                    const sourceId = getFromAttributeId(relationship.leftAttributeId, newNodes);
                    const targetId = getFromAttributeId(relationship.rightAttributeId, newNodes);

                    // Create a unique identifier for the edge
                    const edgeIdentifier = `${sourceId}-${targetId}`;

                    // Check if the edge has already been seen (in reverse as well)
                    if (!seenEdges.has(edgeIdentifier) && !seenEdges.has(`${targetId}-${sourceId}`)) {
                        // If the edge is not seen, add it to the set and return its representation
                        seenEdges.add(edgeIdentifier);
                        seenEdges.add(`${targetId}-${sourceId}`);

                        return {
                            id: `edge-${relationship.id}`,
                            type: "floating",
                            source: sourceId,
                            target: targetId,
                            style: {stroke: "black", strokeWidth: 2},
                        };
                    }
                    // If the edge is seen, return null (to filter it out)
                    return null;

                })
                .filter(edge => edge !== null),
                ...jsonEntityRight.relationships
                    .map((relationship) => {
                        // Get the IDs of the entities involved in the relationship
                        const sourceId = getFromAttributeId(relationship.leftAttributeId, newNodes);
                        const targetId = getFromAttributeId(relationship.rightAttributeId, newNodes);

                        // Create a unique identifier for the edge
                        const edgeIdentifier = `${sourceId}-${targetId}`;

                        // Check if the edge has already been seen (in reverse as well)
                        if (!seenEdges.has(edgeIdentifier) && !seenEdges.has(`${targetId}-${sourceId}`)) {
                            // If the edge is not seen, add it to the set and return its representation
                            seenEdges.add(edgeIdentifier);
                            seenEdges.add(`${targetId}-${sourceId}`);

                            return {
                                id: `edge2-${relationship.id}`,
                                type: "floating",
                                source: sourceId,
                                target: targetId,
                                style: {stroke: "black", strokeWidth: 2},
                            };
                        } else {
                            // If the edge is seen, return null (to filter it out)
                            return null;
                        }
                    })
                    .filter(edge => edge !== null), // Filter out null values (edges that are duplicates)
                ...Array.from(linesToDraw.values()).map((link) => {
                    const info = link.researche
                    const sizeLinks = link.links.size
                    if (newNodes.filter(value => value.id.startsWith("node-2")).map(value => value.id).indexOf(`node-2-${info.leftEntityName}`) === -1){
                        return {
                            id: `link-${link.id}`,
                            type: "floating",
                            source: `node-${info.leftEntityName}`,
                            target: `node-2-${info.rightEntityName}`,
                            label: (sizeLinks > 1)?sizeLinks:"",
                            interactionWidth:20,
                            style: {stroke: `${link.color}`, strokeDasharray:5, strokeWidth: 3, animated: true,},
                            links : link.links
                        };
                    }
                    return {
                        id: `link-${link.id}`,
                        type: "floating",
                        source: `node-2-${info.leftEntityName}`,
                        target: `node-${info.rightEntityName}`,
                        interactionWidth:20,
                        style: {stroke: `${link.color}`, strokeDasharray:5, strokeWidth: 3, animated: true},
                        label: (sizeLinks > 1)?sizeLinks:"",
                        links : link.links
                    };

                } )
            ];

            setEdges(newEdges);
            setEdgesIds(newEdges.map(value => value.id));
            ignore=true
        };

        // Check if selectedDatasetName and selectedDataModelName1 are not null before fetch
        if (selectedDatasetName !== null && selectedDataModelNameLeft !== null) {
            fetchDataModelValues();
        }
    }, [selectedDatasetName, selectedDataModelNameLeft, selectedDataModelNameRight, setNodes, setEdges]);

    useEffect(() => {

        if (!retAtt?.id) {
            return;
        }
        const source = getFromAttributeId(retAtt.leftAttributeId, nodes);
        const target = getFromAttributeId(retAtt.rightAttributeId, nodes);

        // check if there is already a line between those 2 entities
        const filteredEdges = edges.filter(edge => (
                edge.source === source && edge.target === target)
                || (edge.source === target && edge.target === source)
            );

        if (filteredEdges.length !==0) {
            return;
        }
        setEdges(prevState => [...prevState, {
            id: `edge2-${retAtt.id}`,
            type: 'floating',
            source,
            target,
            style: {stroke: "black", strokeWidth: 2},
        }]);
        setEdgesIds(prevState => [...prevState,`edge2-${retAtt.id}`]);
        setRetAtt({});

    }, [retAtt, nodes, edges, setEdges]);

    const handleCloseAlert = useCallback(() => setShowAlert(false), []);

    // Context Menu
    const [panOnDrag, setPanOnDrag] = useState(true);
    const [zoomOnScroll, setZoomOnScroll] = useState(true);
    const [zoomOnDoubleClick, setZoomOnDoubleClick] = useState(true);
    const [nodesDraggable, setNodesDraggable] = useState(true);

    /**
     * Freezes the graph by disabling panning, zooming, and node dragging.
     */
    const freezeGraph = () => {
        setPanOnDrag(false);
        setZoomOnScroll(false);
        setZoomOnDoubleClick(false);
        setNodesDraggable(false);
    }

    /**
     * Unfreezes the graph by enabling panning, zooming, and node dragging.
     */
    const unFreezeGraph = () => {
        setPanOnDrag(true);
        setZoomOnScroll(true);
        setZoomOnDoubleClick(true);
        setNodesDraggable(true);
    }

    const [menu, setMenu] = useState(null);
    const ref = useRef(null);

    // Call back for context menu
    const onNodeContextMenu = useCallback(
        (event, node) => {
            // Prevent native context menu from showing
            event.preventDefault();

            // Calculate position of the context menu. We want to make sure it
            // doesn't get positioned off-screen.
            const pane = ref.current.getBoundingClientRect();
            const top = event.clientY - pane.top;
            const left = event.clientX - pane.left;

            // Freeze graph
            freezeGraph();

            // Set context menu position
            setMenu({
                entityId: node.data.entityId,
                top,
                left
            });
        },
        [setMenu],
    );

    // Close the context menu if it's open whenever the window is clicked.
    const onPaneClick = useCallback(() => {
        // Disable the context menu
        setMenu(null);

        // Unfreeze the graph
        unFreezeGraph();
    }, [setMenu]);

    const handleClickOutsideContextMenu = useCallback(
        (event) => {
            if (menu && !event.target.closest('.context-menu')) {
                // Disable the context menu
                setMenu(null);

                // Unfreeze the graph
                unFreezeGraph();
            }
        },
        [menu]
    );

    // Listener to disable context menu when the mouse outside the menu context menu
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideContextMenu);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideContextMenu);
        };
    }, [handleClickOutsideContextMenu]);

    return (
        <>
            {showAlert && (<Alert variant="danger" onClose={handleCloseAlert} dismissible>
                Une erreur est survenue.
            </Alert>)}
            <div className="graph">
                <FormRelationships data={data} show={show} setShow={setShow} setRetAtt={setRetAtt}/>
                <ReactFlowProvider id="test">
                    <NodeLayouter
                        setNodes={setNodes}
                        setEdges={setEdges}
                        edges={edges}
                        nodes={nodes}
                        nodesIds={nodesIds}
                        edgesIds={edgesIds}
                        instance = {reactFlowInstance}
                    />
                    <ReactFlow
                        onInit={(instance) => setReactFlowInstance(instance)}
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        onEdgeClick = {onEdgeClick}
                        connectionMode={ConnectionMode.Loose}
                        ref={ref}
                        onPaneClick={onPaneClick}
                        onNodeContextMenu={onNodeContextMenu}
                        zoomOnScroll={zoomOnScroll}
                        zoomOnDoubleClick={zoomOnDoubleClick}
                        nodesDraggable={nodesDraggable}
                        panOnDrag={panOnDrag}
                        fitView
                    >
                        <MiniMap/>
                        <Controls/>
                        <Background />
                            {menu && <ContextMenu onClick={onPaneClick} {...menu}
                        />}
                    </ReactFlow>
                    {selectedLink && (
                        <ModalLinkDetails
                            show={showModal}
                            handleClose={() => {
                                setShowModal(false);
                            }}
                            mapLinks={selectedLink}
                        />
                    )}
                </ReactFlowProvider>
            </div>
            <AutoRelationshipsMenu
                getFromAttributeId={getFromAttributeIdConst}
                setEdges={setEdges}
                nodes={nodes}
            />
        </>
    );
}

export default DataModelsGraph;
