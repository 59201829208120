import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Search } from 'react-bootstrap-icons';
import './ContextMenu.css';

/**
 * ContextMenu Component.
 *
 * @param {object} entityId Data associated with the node.
 * @param {number} top The top position of the menu.
 * @param {number} left The left position of the menu.
 * @returns {JSX.Element} A context menu component.
 */
function ContextMenu({entityId, top, left}) {
    const navigate = useNavigate();

    const researchesHistory = useCallback(() => {
        navigate(`/research/entity/${entityId}/history`);
    }, [entityId, navigate]);

    return (
        <div style={{top, left}} className="context-menu">
            <button onClick={researchesHistory}>
                <Search />
                Historique des recherches
            </button>
        </div>
    );
}

export default ContextMenu;