import { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

/**
 * Component encapsulating a modal in which a form allows to create a new link.
 *
 * @param {boolean} show Should the modal be displayed ?
 * @param {Number} medianScore
 * @param {Function} handleClose The callback to call when closing the modal.
 * @param {Number} researchId The id of the current research
 * @returns {JSX.Element} The component.
 */
const CreateLinkForm = ({show, medianScore, handleClose, researchId}) => {
    const [validated, setValidated] = useState(false);
    const [author, setAuthor] = useState("");
    const [label, setLabel] = useState("");
    const [comment, setComment] = useState("");
    const [color, setColor] = useState("#000000");
    const [link, setLink] = useState("");
    const [listValidate, setListValidate] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams({status: "VALIDATE"});

        fetch(`/api/researches/${researchId}/summary?${queryParams}`)
            .then(response => response.json())
            .then(newSummary => setListValidate(newSummary));
    }, [researchId]);

    useEffect(() => {
        if (listValidate.medianScores && listValidate.medianScores.length > 0) {
            setLink("linkCreated");
        } else {
            setLink("linkNoCreated");
        }
    }, [listValidate]);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
          return;
        }

        setValidated(true);

        fetch('/api/links', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                score: medianScore,
                author,
                label,
                comment,
                researchId,
                color
            })
        })
        .then(response => {
            if (response.status !== 201) {
                throw new Error("failed to create link");
            }
            return response.json();
        })
        .then(_ => handleClose())
        .catch(error => console.error(error));

        navigate("/");
    };

    const handleRadioChange = (event) => {
        setLink(event.target.value);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title>Finaliser la revue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {listValidate.medianScores && (
                        <Form.Group>
                            <Form.Check
                                aria-label="radio 1"
                                type="radio"
                                id="linkCreated"
                                name="link"
                                value="linkCreated"
                                label="Créer un lien"
                                checked={link === 'linkCreated'}
                                onChange={handleRadioChange}
                            />
                            <Form.Check
                                aria-label="radio 1"
                                type="radio"
                                id="linkNotCreated"
                                name="link"
                                value="linkNoCreated"
                                label="Ne pas créer de lien"
                                checked={link === 'linkNoCreated' ||
                                    (!listValidate.medianScores.length && setLink("linkNoCreated"))}
                                onChange={handleRadioChange}
                            />
                        </Form.Group>
                    )}
                    {link === "linkCreated" && (
                        <>
                            <Form.Group className="mb-3" controlId="author">
                                <Form.Label>
                                    Auteur<span className="redStar">*</span>
                                </Form.Label>
                                <Form.Control required value={author}
                                              onChange={e => setAuthor(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="label">
                                <Form.Label>
                                    Libellé<span className="redStar">*</span>
                                </Form.Label>
                                <Form.Control required value={label} onChange={e => setLabel(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="color">
                                <Form.Label>Couleur</Form.Label>
                                <HexColorPicker value={color}
                                                onChange={e => setColor(e)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="comment">
                                <Form.Label>Commentaire</Form.Label>
                                <Form.Control as="textarea" rows={3} value={comment}
                                              onChange={e => setComment(e.target.value)} />
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button variant="success" type="submit">
                        Valider
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateLinkForm;