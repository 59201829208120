import { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';

/**
 * A component for selecting a sampling algorithm.
 *
 * @param {string} selectedAlgorithm
 * @param {string[]} algorithms The callback function triggered when an algorithm is selected.
 * @param {Function} onChangeAlgorithm
 * @returns {JSX.Element} The JSX element representing the sampling algorithm selection component.
 */
function SelectorAlgorithms({selectedAlgorithm, algorithms, onChangeAlgorithm}) {
    const [inputValue, setInputValue] = useState(selectedAlgorithm)

    /**
     * Handles the change event when selecting a sampling algorithm.
     * Updates the selected algorithm and triggers the onSelect callback.
     *
     * @param {Event} e The change event.
     */
    const handleOnChange = useCallback(event => {
        const algorithm = event.target.value;
        setInputValue(algorithm)
        onChangeAlgorithm(algorithm);
    }, [onChangeAlgorithm]);

    return (
        <Form.Select
            aria-label="Selecteur d'algorithmes"
            onChange={handleOnChange}
            value={inputValue}
            disabled={algorithms.length === 0}
        >
            {
                algorithms.length === 0
                    ? <option disabled>Algorithmes indisponibles</option>
                    : <>
                        <option>Sélectionner un algorithme</option>
                        {algorithms.map(algorithm => (
                            <option key={algorithm} value={algorithm}>
                                {algorithm}
                            </option>
                        ))}
                    </>
            }
        </Form.Select>
    );
}

export default SelectorAlgorithms;