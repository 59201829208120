import { Container, Row } from 'react-bootstrap';

import { useResearchConfig } from '../ResearchConfigContext';
import MatrixMatchingConfiguration from './components/MatrixMatchingConfiguration';

const MatchingStep = () => {
    const {algorithmsError} = useResearchConfig();

    return (
        <div>
            <Container>
                <Row>
                    <MatrixMatchingConfiguration />
                    {algorithmsError && <div>{algorithmsError.message}</div>}
                </Row>
            </Container>
        </div>
    );
}

export default MatchingStep;