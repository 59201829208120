import { Button, Modal, Spinner } from "react-bootstrap";
import { useCallback, useState } from "react";
import { useDataSelectorContext } from "../DataSelector/DataSelectorContext";

/**
 * Display the modal to launch the auto-relationship
 * @param getFromAttributeId
 * @param setEdges
 * @param nodes
 * @returns {JSX.Element}
 * @constructor
 */
function AutoRelationshipsMenu({getFromAttributeId, setEdges, nodes}) {
    const [loadingRel, setLoadingRel] = useState(false);
    const {
        selectedDatasetName,
        selectedDataModelNameLeft,
        selectedDataModelNameRight,
        showAutoRelations,
        setShowAutoRelations
    } = useDataSelectorContext();

    const handleClose = useCallback(() => {
        setShowAutoRelations(false);
        setLoadingRel(false);
    }, [setShowAutoRelations]);

    const launchRequest = useCallback(async (dmToFetch, idPrefix) => {
        setLoadingRel(true);
        await fetch(
            `/api/datasets/${selectedDatasetName}/datamodels/${dmToFetch}/relationships`,
            {
                method: "POST"
            }).then(value => value.json()).then(datamodel => {
            setEdges(prevState => {
                const added = [];
                const newEdges = datamodel.relationships.map((relationship) => {
                    const source = getFromAttributeId(relationship.leftAttributeId, nodes);
                    const target = getFromAttributeId(relationship.rightAttributeId, nodes);

                    // check if there is already a line between those 2 entities
                    const filteredEdges = prevState.filter(edge => (
                            edge.source === source && edge.target === target)
                        || (edge.source === target && edge.target === source)
                    );

                    if (filteredEdges.length !== 0) {
                        return undefined;
                    }

                    // check if there is a line between those 2 entities in edges we have just added
                    const filteredEdgesNew = added.filter(edge => (
                            edge.source === source && edge.target === target)
                        || (edge.source === target && edge.target === source)
                    );

                    if (filteredEdgesNew.length !== 0) {
                        return undefined;
                    }
                    const newEdge = {
                        id: `${idPrefix}-${relationship.id}`,
                        type: 'floating',
                        source: getFromAttributeId(relationship.leftAttributeId, nodes),
                        target: getFromAttributeId(relationship.rightAttributeId, nodes),
                        style: {stroke: "black", strokeWidth: 2},
                    };
                    added.push(newEdge);
                    return newEdge;
                });
                return [...prevState, ...newEdges.filter(value => value !== undefined)];
            });
            setLoadingRel(false);
            setShowAutoRelations(false);
        });
    }, [selectedDatasetName, nodes, setEdges, setShowAutoRelations, getFromAttributeId]);

    const launchRequestLeft = useCallback(() => {
        launchRequest(selectedDataModelNameLeft, "edge");
    }, [launchRequest, selectedDataModelNameLeft]);
    const launchRequestRight = useCallback(() => {
        launchRequest(selectedDataModelNameRight, "edge2");
    }, [launchRequest, selectedDataModelNameRight]);

    return (
        <Modal
            show={Boolean(showAutoRelations)}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="formRelPopup"
        >
            <Modal.Header>
                <Modal.Title>Recherche automatique de relations</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sélectionner un modèle de données pour effectuer la recherche automatique.
                <div style={{display: "block", textAlign: "center"}} hidden={!loadingRel}>
                    <Spinner animation="border" role="status"/>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" disabled={loadingRel} className="py-2 px-4 rounded" onClick={handleClose}>Retour</Button>

                <Button
                    className="py-2 px-4 rounded"
                    style={{backgroundColor: "#e76565", borderColor: "#e76565"}}
                    onClick={launchRequestLeft}
                    disabled={loadingRel}
                >{selectedDataModelNameLeft} </Button>

                <Button
                    className="py-2 px-4 rounded"
                    style={{backgroundColor: "#65c1e7", borderColor: "#65c1e7"}}
                    onClick={launchRequestRight}
                    disabled={selectedDataModelNameRight === null || loadingRel}
                >
                    {selectedDataModelNameRight === null ? "Data Model 2" : selectedDataModelNameRight} </Button>

            </Modal.Footer>
        </Modal>
    );
}

export default AutoRelationshipsMenu;

