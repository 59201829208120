import { DataSelectorProvider } from './components/DataSelector/DataSelectorContext';
import DataModelsGraph from './components/DataModels/DataModelsGraph';
import Header from './components/Header/Header';

/**
 * Component for displaying main page.
 *
 * @returns {JSX.Element}return the JSX element to display the main page (header + modelDisplayer).
 */
function MainPage() {
    return (
        <DataSelectorProvider>
            <Header />
            <DataModelsGraph />
        </DataSelectorProvider>
    );
}

export default MainPage;
