import { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';

/**
 * An input of a dynamic form.
 *
 * @param {string} name The name of the input.
 * @param value
 * @param {string} type The type of the input.
 * @param {function} onValueChange The callback function to call when the value of the input changes.
 * @returns {JSX.Element} The corresponding input.
 */
const CustomInput = ({name, value = '', type, onValueChange}) => {
    const [inputValue, setInputValue] = useState(value);
    const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);

    const handleChange = useCallback((event) => {
        setInputValue(event.target.value)
    }, []);


    const typeMap = {
        "string": "text",
        "integer": "number",
        "any": "text"
    }

    return (
        <Form.Group>
            <Form.Label className="mt-2">{capitalizedName}</Form.Label>
            <Form.Control
                value={inputValue}
                onChange={handleChange}
                type={typeMap[type]}
                onBlur={event => onValueChange(event)}
                min={1}
            />
        </Form.Group>
    );
}

export default CustomInput;