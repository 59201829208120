import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';

import DataModelsSelector from '../DataSelector/DataModelsSelector';
import DatasetSelector from '../DataSelector/DatasetSelector';
import { useDataSelectorContext } from '../DataSelector/DataSelectorContext';
import FormDataset from '../FormDataset';
import './Header.css';


/**
 * Component for displaying header.
 *
 * @returns {JSX.Element} return the JSX element to display the header (logo + data models / dataset selectors).
 */
function Header() {
    const { setShowAutoRelations, selectedDataModelNameLeft } = useDataSelectorContext();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleButtonClick = useCallback(() => {
        setShowAutoRelations(true);
    }, [setShowAutoRelations]);

    return (
        <div className="header">
            <div className="logo-container">
                <img alt="Logo OpusNumeris" src="OpusNumeris.jpg" />
            </div>
            <div className="data-selectors">
                <Button onClick={handleShow}>Ajouter un dataset</Button>
                <FormDataset show={show} handleClose={handleClose} />
                <DatasetSelector />
                <DataModelsSelector />
            </div>
            <div className="auto-relationships-button-container">
                <Button disabled={selectedDataModelNameLeft === null} className="auto-relationships-button" onClick={handleButtonClick}>
                    Recherche auto. de relations
                </Button>
            </div>
        </div>
    );
}

export default Header;
