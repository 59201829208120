import { Card, ListGroup } from 'react-bootstrap';
import MarcherConfigurationItems from './MarcherConfigurationItems';

/**
 * Component representing the overall configuration of matching algorithms.
 *
 * @param {Object} research The research object.
 * @param {Array} matcherConfigurations An array of matcher configurations.
 * @returns {JSX.Element} The rendered component.
 */
const MatcherConfiguration = ({research ,matcherConfigurations}) => {
    return(
        <Card>
            <Card.Body>
                <Card.Title>Configurations des algorithmes de matching</Card.Title>
                <ListGroup className="scrollable-history-matcher-config">
                    {matcherConfigurations.map(matcher => (
                        <MarcherConfigurationItems key={matcher.id} research={research} matcher={matcher} />
                    ))}
                </ListGroup>
            </Card.Body>
        </Card>
    );
}

export default MatcherConfiguration;