import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useResearchConfig } from '../ResearchConfigContext';

/**
 * A React component to select attributes from two entities for comparison.
 *
 * @component
 */
function AttributesStep() {
    const {
        leftEntity,
        rightEntity,
        matchingLeftAttributes,
        matchingRightAttributes,
        setMatchingLeftAttributes,
        setMatchingRightAttributes
    } = useResearchConfig();

    // Initialize state with the current matching attributes for each entity
    const [leftAttributesCheck, setLeftAttributesCheck] = useState(matchingLeftAttributes.map(attr => attr.name));
    const [rightAttributesCheck, setRightAttributesCheck] = useState(matchingRightAttributes.map(attr => attr.name));

    // Update matching attributes arrays directly
    const handleSelectAll = useCallback((e) => {
        const {name, checked} = e.target;
        const entity = name === "att1" ? leftEntity : rightEntity;
        const setMatchingAttributes = name === "att1" ? setMatchingLeftAttributes : setMatchingRightAttributes;

        if (checked) {
            setMatchingAttributes(entity.attributes);
        } else {
            setMatchingAttributes([]);
        }
    }, [leftEntity, rightEntity, setMatchingLeftAttributes, setMatchingRightAttributes]);

    const handleClick = useCallback((e) => {
        const {value, name, checked} = e.target;
        const entityName = name === "att1" ? "left" : "right";
        const entity = entityName === "left" ? leftEntity : rightEntity;
        const setMatchingAttributes = entityName === "left" ? setMatchingLeftAttributes : setMatchingRightAttributes;

        const attribute = entity.attributes.find(attr => attr.name === value);

        // Update matching attributes array based on the action
        setMatchingAttributes(prev => checked ? [...prev, attribute] : prev.filter(item => item.name !== value));
    }, [leftEntity, rightEntity, setMatchingLeftAttributes, setMatchingRightAttributes]);

    // Ensure state is in sync with matching attributes when the component mounts or updates
    useEffect(() => {
        setLeftAttributesCheck(matchingLeftAttributes.map(attr => attr.name));
        setRightAttributesCheck(matchingRightAttributes.map(attr => attr.name));
    }, [matchingLeftAttributes, matchingRightAttributes]);

    const renderAttributes = (entity, entityName, attributesCheck) => (
        <div className={`${entityName}DivFormRl`}>
            {entityName === "left" ? "Entité 1" : "Entité 2"} : {entity.name}
            <Form.Group name={`att${entityName === 'left' ? '1' : '2'}`}>
                <Form.Check
                    name={`att${entityName === 'left' ? '1' : '2'}`}
                    label="Select All"
                    value="Select All"
                    type="checkbox"
                    id={`att${entityName === 'left' ? '1' : '2'}`}
                    key={`att${entityName === 'left' ? '1' : '2'}`}
                    onChange={handleSelectAll}
                    checked={attributesCheck.length === entity.attributes.length}
                />
                {entity.attributes.map(attribute => (
                    <Form.Check
                        name={`att${entityName === 'left' ? '1' : '2'}`}
                        label={attribute.name}
                        value={attribute.name}
                        id={`att${entityName === 'left' ? '1' : '2'} ${attribute.name}`}
                        key={`att${entityName === 'left' ? '1' : '2'} ${attribute.name}`}
                        onChange={handleClick}
                        checked={attributesCheck.includes(attribute.name)}
                        type="checkbox"
                    />
                ))}
            </Form.Group>
        </div>
    );

    return (
        <Form id="formAddingRelationship" className="w-full max-w-sm">
            <div>Quels attributs souhaitez vous comparer?</div>
            <br/>
            {renderAttributes(leftEntity, 'left', leftAttributesCheck)}
            {renderAttributes(rightEntity, 'right', rightAttributesCheck)}
        </Form>
    );
}

export default AttributesStep;