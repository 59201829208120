import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Container, Row, Col, Button } from 'react-bootstrap';
import MatcherConfiguration from './components/MatcherConfiguration';
import ResearchInfo from './components/ResearchInfo';
import SamplingConfigurationInfo from './components/SamplingConfigurationInfo';

/**
 * Renders a page displaying detailed information about research from the entity history.
 *
 * @returns {JSX.Element} The EntityResearchHistoryDetailPage component.
 */
function EntityResearchHistoryDetailPage() {
    const { researchId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [research, setResearch] = useState(null);
    const [leftEntity, setLeftEntity] = useState({});
    const [rightEntity, setRightEntity] = useState({});

    /**
     * Navigates back to the previous page.
     */
    const returnBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const goToReview = () => {
        navigate(`/research/${researchId}/review`, {state: {from: location.pathname}});
    };

    const fetchEntity = (entityId, setEntity) => {
        fetch(`/api/entities/${entityId}`)
        .then(response => response.json())
        .then(entity => setEntity(entity))
    }

    useEffect(() => {
        const id = parseInt(researchId);

        // Fetch data research with specific id
        fetch(`/api/researches/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Server returned : ${response.status}`);
                }
                return response.json();
            })
            .then(researchData => {
                setResearch(researchData);
                fetchEntity(researchData.leftEntityId, setLeftEntity);
                fetchEntity(researchData.rightEntityId, setRightEntity);
            })
            .catch(() => setResearch(null));
    }, [researchId, setLeftEntity, setRightEntity]);

    return (
        <Container fluid className="mt-4">
            <Row className="mb-3">
                <h2>Détail de la recherche</h2>
            </Row>
            {research ? (
                <Row className="mb-3">
                    <Col md={12} lg={6}>
                        <ResearchInfo
                            research={research}
                            leftEntityName={leftEntity.name}
                            rightEntityName={rightEntity.name}
                        />
                    </Col>
                    <Col md={12} lg={6}>
                        <SamplingConfigurationInfo
                            research={research}
                            leftEntityName={leftEntity.name}
                            rightEntityName={rightEntity.name}
                        />
                    </Col>
                    <Col>
                        <MatcherConfiguration research={research} matcherConfigurations={research.matcherConfigurations} />
                    </Col>
                </Row>
            )
            :
                <p>Pas d&apos;information disponible pour cette recherche.</p>
            }
            <Row>
                <Col className="d-flex flex-row justify-content-center w-50">
                    <Button className="me-3 w-25" variant="outline-primary" onClick={returnBack}>Retour</Button>
                    <Button className="w-25" onClick={goToReview}>Accéder à la revue</Button>
                </Col>
            </Row>
        </Container>

    );
}

export default EntityResearchHistoryDetailPage;
