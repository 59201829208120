import { useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';

import DynamicForm from '../../common/DynamicForm';
import { useResearchConfig } from '../../ResearchConfigContext';
import SelectorAlgorithms from "../../common/SelectorAlgorithms";

/**
 * Component representing a form to specify the configuration of a sampling algorithm.
 *
 * @param {String} entityName The name of the entity tied to the future configuration.
 * @param {Object} config The list of algorithm available
 * @param {Function} onConfigChange The callback function to call when the configuration changes.
 * @returns {JSX.Element} The component.
 */
const SamplingAlgorithmForm = ({entityName, samplerConfig, onConfigChange}) => {
    const {algorithms} = useResearchConfig();
    const [algorithmName, setAlgorithmName] = useState(samplerConfig.algorithmName);
    const samplingAlgorithms = algorithms.filter(algorithm => algorithm.type === "SAMPLING").map(algo => algo.name);
    const algorithm = algorithms.find(algo => algo.name === algorithmName);

    /**
     * Callback function triggered when the configuration changes.
     *
     * @param {[Object]} configParams The new configuration
     */
    const handleConfigChange = (configParams) => {
        const newConfig = {
            algorithmName,
            parameters: configParams
        }
        onConfigChange(newConfig);
    };

    return (
        <Col>
            {
                entityName
                    ? <span>{entityName}</span>
                    : <Spinner animation="border"/>
            }
            <SelectorAlgorithms
                currentAlgorithm={algorithmName}
                algorithms={samplingAlgorithms}
                onChangeAlgorithm={setAlgorithmName}
            />
            {algorithm &&
                <DynamicForm
                    parameters={samplerConfig.parameters ?? algorithm.parameters}
                    onParametersChange={handleConfigChange}
                />
            }
        </Col>
    )
};

export default SamplingAlgorithmForm;