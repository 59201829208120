import { Form, ListGroupItem } from 'react-bootstrap';
import { format } from '../../../common/format';

/**
 * Renders a list group item displaying information about a link.
 *
 * @param {Object} link The link object containing link details.
 */
function LinkInfo({ link }) {
    return (
        <ListGroupItem>
            Lien :
            <ul className="pl-3">
                <li>ID : {link.id}</li>
                <li>Score : {format(link.score)}</li>
                <li>Auteur : {link.author}</li>
                <li>Label : {link.label}</li>
                <li>Commentaire : {link.comment}</li>
                <li>Couleur : {link.color}
                    <Form.Control
                        type="color"
                        className="ml-2 form-control form-control-color"
                        id="colorLink"
                        defaultValue={link.color}
                        title="Couleur lien"
                        disabled
                    />
                </li>
                <li>Date de cr&eacute;ation : {link.createdAt}</li>
            </ul>
        </ListGroupItem>
    );
}

export default LinkInfo;