import { ListGroupItem } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';

/**
 * Renders a list group item for sampling configuration.
 *
 * @param {string} entityName The name of the entity.
 * @param {string} sampleSize The size of the sample.
 * @param {string} algorithmName The name of the sampling algorithm.
 */
function SamplingConfigItem({ entityName, sampleSize, algorithmName }) {
    return (
        <ListGroupItem>
            <div>
                <ArrowRight /> {entityName} :
            </div>
            <ul className="pl-3">
                <li>Taille de l&apos;&eacute;chantillon : {sampleSize}</li>
                <li>Algorithme : {algorithmName}</li>
            </ul>
        </ListGroupItem>
    );
}

export default SamplingConfigItem;