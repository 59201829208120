import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ResponseError from '../../ResponseError';

/**
 * Context for managing research configuration in the application.
 * @type {React.Context}
 */
const ResearchConfigContext = createContext();

/**
 * Provider for the ResearchConfigContext.
 * It fetches research data based on the researchId and provides state management for various configurations.
 *
 * @param {Object} props - The props object.
 * @param {ReactNode} props.children - The children elements to be rendered inside the provider.
 * @returns {ReactNode} The children elements wrapped in the ResearchConfigContext provider.
 */
export const ResearchConfigProvider = ({children}) => {
    const [research, setResearch] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [algorithms, setAlgorithms] = useState([]);
    const [algorithmsError, setAlgorithmsError] = useState(null);
    const [leftEntity, setLeftEntity] = useState({});
    const [rightEntity, setRightEntity] = useState({});
    const [leftRecords, setLeftRecords] = useState({entityName: '', records: []});
    const [rightRecords, setRightRecords] = useState({entityName: '', records: []});
    const [matchingLeftAttributes, setMatchingLeftAttributes] = useState([]);
    const [matchingRightAttributes, setMatchingRightAttributes] = useState([]);
    const [leftSamplerConfiguration, setLeftSamplerConfiguration] = useState({});
    const [rightSamplerConfiguration, setRightSamplerConfiguration] = useState({});
    const [matchingConfigs, setMatchingConfigs] = useState([]);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const {researchId} = useParams();

    /**
     * Asynchronously fetches a list of algorithms from a specified endpoint.
     *
     * @returns {Promise<void>} Resolves once the fetch operation is complete.
     *
     * This function is responsible for making an HTTP GET request to the '/algorithms' endpoint.
     * Upon successful retrieval, it updates the state with the fetched algorithms.
     * In case of an error, it sets the error state with the error message.
     */
    const fetchAlgorithms = async () => {
        try {
            const response = await fetch('/api/algorithms');
            if (response.ok) {
                const algorithmsResponse = await response.json();
                setAlgorithms(algorithmsResponse)
            } else {
                setAlgorithmsError(new ResponseError('Echec de l\'extraction des algorithmes', response));
            }
        } catch (error) {
            setAlgorithmsError(error);
        }
    };

    useEffect(() => {
        /**
         * Asynchronously fetches the research by id and the corresponding entities
         *
         * @returns {Promise<void>} Resolves once the fetch operation is complete.
         */
        const fetchResearchAndEntities = async () => {
            const researchResponse = await fetch(`/api/researches/${researchId}`);
            if (!researchResponse.ok) {
                navigate("/");
                return;
            }
            const currentResearch = await researchResponse.json();
            setResearch(currentResearch);

            if (currentResearch.leftEntityId) {
                const leftEntityResponse = await fetch(`/api/entities/${currentResearch.leftEntityId}`);
                const res = await leftEntityResponse.json();
                setLeftEntity(res);
            }

            if (currentResearch.rightEntityId) {
                const rightEntityResponse = await fetch(`/api/entities/${currentResearch.rightEntityId}`);
                const res = await rightEntityResponse.json();
                setRightEntity(res);
            }
        };

        fetchResearchAndEntities().then(() => fetchAlgorithms());
    }, [researchId, navigate]);

    /**
     * Set on the visibility of a modal or overlay.
     */
    const handleClose = () => setShow(false);
    /**
     * Set off the visibility of a modal or overlay.
     */
    const handleShow = () => setShow(true);

    /**
     * Update the configuration for a specific research.
     *
     * @param {Object} config The configuration object
     * @param {String} endpoint The API endpoint to update
     */
    const updateConfiguration = async (config, endpoint) => {
        const response = await fetch(`/api/researches/${researchId}${endpoint}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(config)
        });

        if (!response.ok) {
            throw new Error("Failed to update configuration");
        }
    }

    return (
        <ResearchConfigContext.Provider value={{
            research,
            activeStep,
            setActiveStep,
            algorithms,
            algorithmsError,
            leftEntity,
            rightEntity,
            leftRecords,
            setLeftRecords,
            rightRecords,
            setRightRecords,
            matchingLeftAttributes,
            setMatchingLeftAttributes,
            matchingRightAttributes,
            setMatchingRightAttributes,
            leftSamplerConfiguration,
            setLeftSamplerConfiguration,
            rightSamplerConfiguration,
            setRightSamplerConfiguration,
            matchingConfigs,
            setMatchingConfigs,
            show,
            handleClose,
            handleShow,
            researchId,
            navigate,
            updateConfiguration,
        }}>
            {children}
        </ResearchConfigContext.Provider>
    );
};

/**
 * Custom hook to use the ResearchConfigContext.
 *
 * @returns {Object} The context value.
 */
export const useResearchConfig = () => useContext(ResearchConfigContext);
