import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useResearchConfig } from '../ResearchConfigContext';

/**
 * Component used to retrieve user's confirmation on weither the current researches' configuration
 * should be dropped or not. The component is a modal.
 *
 * @returns {JSX.Element} The component.
 */
const DropConfig = () => {
    const {researchId, show, handleClose, navigate} = useResearchConfig();

    /**
     * function to handle confirmation button
     */
    const handleConfirm = () => {
        fetch(`/api/researches/${researchId}`, {method: 'DELETE'})
            .then(response => {
                if (response.status !== 204) {
                    throw new Error("Failed to delete the research.");
                }
                handleClose();
                navigate("/");
            });
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>Voulez-vous vraiment abandonner cette recherche ?</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleClose}>
                    Retour
                </Button>
                <Button variant="danger" onClick={handleConfirm}>
                    Oui
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DropConfig;

