import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Calendar, Database } from 'react-bootstrap-icons';

import FormattedDate from '../FormattedDate';
import LinkInfo from './LinkInfo';

/**
 * Renders information about research.
 *
 * @param {Object} research The research object containing research details.
 */
function ResearchInfo({ research, leftEntityName, rightEntityName }) {
    /**
     * Renders a list group item displaying a label and a date value.
     *
     * @param {string} label The label to display.
     * @param {string} date The date value to display.
     * @returns {JSX.Element} The JSX element representing the list group item.
     */
    const renderDate = (label, date) => (
        <ListGroupItem>
            <Calendar /> {label} : {date ? <FormattedDate dateString={date} /> : "N/A"}
        </ListGroupItem>
    );

    /**
     * Renders a list group item displaying two entity names.
     *
     * @param {string} leftName The name of the left entity.
     * @param {string} rightName The name of the right entity.
     * @returns {JSX.Element} The JSX element representing the list group item.
     */
    const renderEntities = (leftName, rightName) => (
        <ListGroupItem>
            Entit&eacute;s :
            <ul className="pl-3">
                <li>{leftName}</li>
                <li>{rightName}</li>
            </ul>
        </ListGroupItem>
    );

    return (
        <Card className="h-100">
            <Card.Body>
                <Card.Title>Recherche {research.id}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    Informations de la recherche
                </Card.Subtitle>
                <ListGroup variant="flush">
                    {renderDate("Date de début", research.startedAt)}
                    {renderDate("Date de fin", research.finishedAt)}
                    <ListGroupItem>
                        <Database /> Dataset : {research.dataset}
                    </ListGroupItem>
                    {renderEntities(leftEntityName, rightEntityName)}
                </ListGroup>
                {research.link &&
                    <LinkInfo link={research.link} />}
            </Card.Body>
        </Card>
    );
}

export default ResearchInfo;
