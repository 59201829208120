import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

/**
 *
 * @param onSelectChange to display or not the "ajouter" button if both attributes are selected
 * @param data
 * @returns {JSX.Element} the JSX element with the body of the popup
 * @constructor
 */
function BodyFormRelationships({onSelectChange, data}) {
    const jsonVals = data;
    const [selectedValues, setSelectedValues] = useState({
        att1: -1,
        att2: -1
    });

    const handleSelectChange = useCallback((event) => {
        const {name, id} = event.target;
        setSelectedValues(prevState => {
            return {
                ...prevState,
                [name]: id
            };
        });
    }, []);

    useEffect(() => {
        if (selectedValues.att1 !== -1 && selectedValues.att2 !== -1) {
            onSelectChange(selectedValues);
        }
    }, [selectedValues, onSelectChange]);

    return (
        <Form
            onSubmit={useCallback((e) => {
                e.preventDefault();
                //post the relationship
            }, [])}
            id="formAddingRelationship"
            className="w-full max-w-sm"
        >
            <div>Sélectionner les attributs pour créer une relation.</div>
            <br/>
            <div className="leftDivFormRl">
                Entité 1 : {jsonVals.entity1.name}
                <Form.Group name="att1" value={selectedValues.att1} onChange={handleSelectChange}>
                    {jsonVals.entity1.attributes.map((attribute) =>
                        <Form.Check name="att1" label={attribute.name} value={attribute.name}
                                    id={attribute.id}
                                    key={`att1 ${attribute.name}`} type="radio"/>
                    )}
                </Form.Group>
            </div>
            <div className="rightDivFormRl">
                Entité 2 : {jsonVals.entity2.name}
                <Form.Group name="att2" value={selectedValues.att2} onChange={handleSelectChange}>
                    {jsonVals.entity2.attributes.map((attribute) =>
                        <Form.Check name="att2" label={attribute.name} value={attribute.name}
                                    id={attribute.id}
                                    key={`att2 ${attribute.name}`} type="radio"/>
                    )}
                </Form.Group>
            </div>
        </Form>
    );
}

export default BodyFormRelationships;
