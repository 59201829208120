import { ListGroup, Modal } from "react-bootstrap";
import LinkItems from "./LinkItems";

/**
 * Modal component to display link details.
 *
 * @param {Object} props Component properties.
 * @param {boolean} props.show Controls the visibility of the modal.
 * @param {Function} props.handleClose Callback function to handle modal close.
 * @param {Array} props.mapLinks Array of links data to display in the modal.
 *
 * @returns {JSX.Element} ModalLinkDetails component.
 */
const ModalLinkDetails = ({ show, handleClose, mapLinks}) => {
    const elementsModal = [];
    for(const values of mapLinks) {
        if(Array.isArray(values) && values.length > 0) {
            const links = values[1];
            
            elementsModal.push(
                <LinkItems
                    key={links[0].id}
                    score={links[0].score}
                    author={links[0].author}
                    label={links[0].label}
                    color={links[0].color}
                    researchId={links[1].id}
                />
            )
        }
    }

    return (
      <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
              <Modal.Title>Détails des liens</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <ListGroup>
                  {elementsModal}
              </ListGroup>
          </Modal.Body>
      </Modal>
    );
}

export default ModalLinkDetails;