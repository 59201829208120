import { Handle, Position } from 'reactflow';
import './Entity.css';

/**
 * EntityNode component representing an entity node in a flow diagram.
 *
 * @param {object} props The props passed to the EntityNode component.
 * @param {object} props.data The data object containing information about the entity.
 * @param {string} props.data.title The title of the entity.
 * @param {Array<string>} props.data.attrs An array of attributes associated with the entity.
 * @returns {JSX.Element} Returns the JSX element representing the entity node.
 */
function EntityNode(props) {
    const title = props.data.title;
    const splitedTitle = title.split('_').slice(2,10);
    const resultWithoutDsAndDm = splitedTitle.join("_")
    return (
        <>
            <div className="entity">
                <p>{resultWithoutDsAndDm}</p>
                {props.data.attrs.map(item => <div key={item}>{item}</div>)}
            </div>

            <Handle type="source" position={Position.Top} id="a" />
            <Handle type="source" position={Position.Right} id="b" />
            <Handle type="source" position={Position.Bottom} id="c" />
            <Handle type="source" position={Position.Left} id="d" />

        </>
    );
}

export default EntityNode;
