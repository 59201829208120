import { useCallback, useEffect, useState } from 'react';

import { useDataSelectorContext } from './DataSelectorContext';
import DataModelSelector from './DataModelSelector';
import ResponseError from '../../../../ResponseError'

/**
 * Component for selecting data models based on the selected dataset.
 *
 * @returns {JSX.Element} DataModelsSelector component.
 */
function DataModelsSelector() {
    const {
        selectedDatasetName,
        selectedDataModelNameLeft,
        setSelectedDataModelNameLeft,
        selectedDataModelNameRight,
        setSelectedDataModelNameRight
    } = useDataSelectorContext();
    const [dataModelNames, setDataModelNames] = useState([]);
    const [dataModelsSelectorError, setDataModelsSelectorError] = useState(null);

    useEffect(() => {
        /**
         * Fetch data model names.
         *
         * @returns {Promise<void>} data model names.
         */
        const fetchDataModelNames = async () => {
            try {
                const response = await fetch(`/api/datasets/${selectedDatasetName}/datamodels`);
                if (response.ok) {
                    const json = await response.json();
                    setDataModelNames(json);
                } else {
                    setDataModelsSelectorError(new ResponseError('Echec de l\'extraction des noms des datasets', response));
                }
            } catch (error) {
                setDataModelsSelectorError(error);
            }
        };

        if (selectedDatasetName !== null) {
            fetchDataModelNames();
        }
    }, [selectedDatasetName, setDataModelNames, setDataModelsSelectorError]);

    /**
     * Handler for selecting the first data model name.
     *
     * @function handleOptionChange1
     * @param {Object} event The event object representing the change event with the data model name.
     */
    const handleOptionChange1 = useCallback((event) => {
      setSelectedDataModelNameLeft(event.value);
      setSelectedDataModelNameRight(null);
    }, [setSelectedDataModelNameLeft, setSelectedDataModelNameRight]);

    /**
     * Handler for selecting the second data model name.
     *
     * @function handleOptionChange2
     * @param {Object} event The event object representing the change event with the data model name.
     */
    const handleOptionChange2 = useCallback((event) => {
        setSelectedDataModelNameRight(event.value);
    }, [setSelectedDataModelNameRight]);

    return (
        <>
            <DataModelSelector
                dataModelNames={dataModelNames.filter(dataModelName => dataModelName !== selectedDataModelNameLeft)}
                handleSelectOptionChange={handleOptionChange1}
                isDisabledSelect={selectedDatasetName === null}
                isLeft
                isControlShouldRenderValue={selectedDataModelNameLeft !== null}
                error={dataModelsSelectorError}
            />
            <DataModelSelector
                dataModelNames={dataModelNames.filter(dataModelName => dataModelName !== selectedDataModelNameLeft && dataModelName !== selectedDataModelNameRight)}
                handleSelectOptionChange={handleOptionChange2}
                isDisabledSelect={selectedDataModelNameLeft === null}
                isLeft={false}
                isControlShouldRenderValue={selectedDataModelNameRight !== null && selectedDataModelNameLeft !== null}
                error={dataModelsSelectorError}
            />
        </>
    );
}

export default DataModelsSelector;