/**
 * Represents an error that occurred during a fetch response.
 *
 * @extends Error Response error.
 */
class ResponseError extends Error {
    /**
     * Creates a new ResponseError.
     *
     * @param {string} message The error message.
     * @param {Response} response The response object.
     */
    constructor(message, response) {
        super(message);
        /**
         * The response object associated with the error.
         *
         * @type {Response} Response after the fetch.
         */
        this.response = response;
    }
}

export default ResponseError;