import { useEffect } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import DataPreview from "./DataPreview";
import { useResearchConfig } from "../ResearchConfigContext";
import _ from 'lodash';

/**
 * Functional component representing the Data Preview step.
 * It includes the DataSelectorProvider, Header, DataPreviewContainer, and StepButtons components.
 *
 * @returns {JSX.Element} The JSX element for the DataPreviewStep.
 */
const DataPreviewStep = () => {
    const {leftEntity, rightEntity, leftRecords, rightRecords, setLeftRecords, setRightRecords} = useResearchConfig();

    useEffect(() => {
        /**
         * Fetch necessary data for the component if not already fetched.
         * @returns {Promise<void>}
         */
        const fetchData = async () => {
            // Check if the data is already fetched for both entities
            if (!leftRecords.entityName || !rightRecords.entityName) {
                try {
                    const [leftEntityRecordsResponse, rightEntityRecordsResponse] = await Promise.all([
                        fetch(`/api/entities/${leftEntity.id}/records`),
                        fetch(`/api/entities/${rightEntity.id}/records`)
                    ]);
                    const leftEntityRecordsData = await leftEntityRecordsResponse.json();
                    const rightEntityRecordsData = await rightEntityRecordsResponse.json();
                    setLeftRecords(leftEntityRecordsData);
                    setRightRecords(rightEntityRecordsData);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchData().then();
    }, [leftEntity, rightEntity, leftRecords, rightRecords, setLeftRecords, setRightRecords]);

    return (
        <div>
            <Container>
                <Row>
                    {
                        _.isEmpty(leftRecords)
                            ? <Spinner animation="grow"/>
                            : <>
                                <h4>{leftRecords.entityName}</h4>
                                <DataPreview entity={leftRecords.records}/>
                            </>
                    }
                </Row>
                <br />
                <Row>
                    {
                        _.isEmpty(rightRecords)
                            ? <Spinner animation="grow"/>
                            : <>
                                <h4>{rightRecords.entityName}</h4>
                                <DataPreview entity={rightRecords.records}/>
                            </>
                    }
                </Row>
            </Container>
        </div>
    );
}

export default DataPreviewStep;