import { Button, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import format from "../../../../common/format";

/**
 * Individual item representing a link in the list.
 *
 * @param {Object} props Component properties.
 * @param {number} props.score The score of the link.
 * @param {string} props.color The color of the link.
 * @param {string} props.author The author of the link.
 * @param {string} props.researchId The ID of the associated research.
 *
 * @returns {JSX.Element} LinkItems component.
 */
const LinkItems = ({score, color, author, label, researchId}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleNavigate = () => {
        navigate(`/research/${researchId}/review`, {state: {from: location.pathname }});
    }

    return (
        <ListGroup.Item>
            <Row className="align-items-center">
                <Col>
                    Couleur : {color}
                    <Form.Control
                        type="color"
                        className="ml-2 form-control form-control-color"
                        id="colorLink"
                        defaultValue={color}
                        title="Couleur lien"
                        disabled
                    />
                </Col>
                <Col>Label : {label}</Col>
                <Col>Score : {format(score)}</Col>
                <Col>Auteur : {author}</Col>
                <Col>
                    <Button type="link" onClick={handleNavigate}>Accéder à la revue</Button>
                </Col>
            </Row>
        </ListGroup.Item>
    );
}

export default LinkItems;