import { useState } from 'react';
import { Stepper } from 'react-form-stepper';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ResearchSummary from './summary/ResearchSummary';
import ComparisonsDetails from './details/ComparisonsDetails';
import FinalResult from './finish/FinalResult';
import CreateLinkForm from './summary/components/CreateLinkForm';
import './ClericalReview.css';

const ClericalReview = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [show, setShow] = useState(false);
    const [medianScore, setMedianScore] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {researchId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isComingFromDetailsLink = location.state && location.state.from === "/";
    const isComingFromResearchHistoryDetails = location.state && location.state.from === `/research/${researchId}/details`;
    const [hasNoComparisons, setHasNoComparisons] = useState(false)

    const steps = [
        {
            label: 'Résumé de la recherche',
            component: <ResearchSummary setHasNoComparisons={setHasNoComparisons}/>
        },
        {
            label: 'Revue des comparaisons',
            component: <ComparisonsDetails/>
        },
        {
            label: 'Bilan',
            component: <FinalResult setMedianScore={setMedianScore}/>
        }
    ];

    /**
     * Handles navigation to the next step.
     */
    const handleNextStep = () => {
        if (activeStep < steps.length - 1) {
            if (hasNoComparisons){
                setActiveStep(activeStep+2)
                return
            }
            setActiveStep(activeStep + 1);
        }
    };

    /**
     * Handles navigation to the previous step.
     */
    const handlePreviousStep = () => {
        if (activeStep > 0) {
            if (hasNoComparisons){
                setActiveStep(activeStep-2)
                return
            }
            setActiveStep(activeStep - 1);
        }
    };

    /**
     * Handles navigation to the previous page research history details or home.
     */
    const handleReturnStep = () => {
        if ((isComingFromDetailsLink || isComingFromResearchHistoryDetails)) {
            navigate(-1);
        } else {
            navigate("/");
        }
    };

    /**
     * Returns the component for the current active step.
     *
     * @returns {JSX.Element} The component for the current active step.
     */
    const getSectionComponent = () => {
        const currentStep = steps[activeStep];
        return currentStep ? currentStep.component : <div>Error.</div>;
    };

    const nextButton = () => {
        if (activeStep !== 2) {
            return (
                <Button className="m-2" type="button" variant="primary" onClick={handleNextStep}>
                    Suivant
                </Button>
            );
        }
        return (
            <>
                <CreateLinkForm show={show} medianScore={medianScore} handleClose={handleClose}
                                researchId={researchId}/>
                <Button variant={"success"} onClick={handleShow}>Finaliser la revue</Button>
            </>
        );
    };

    return (
        <div className="clerical-review">
            <div className="clerical-review-header">
                <div className="clerical-review-title">Résumé de la recherche</div>
                <Stepper
                    steps={steps.map(step => ({label: step.label}))}
                    activeStep={activeStep}
                    className="font-raleway"
                />
            </div>
            <div className="clerical-review-body">
                {getSectionComponent()}
            </div>
            <div className="clerical-review-footer">
                <Button className="m-4" variant="outline-danger" onClick={handleReturnStep}>Retour</Button>
                {activeStep !== 0 
                    && <Button className="m-4" variant="outline-primary" onClick={handlePreviousStep}>
                        Précédent
                    </Button>
                }
                {nextButton()}
            </div>
        </div>
    );
}

export default ClericalReview;