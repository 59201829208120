import { useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useDataSelectorContext } from './DataSelector/DataSelectorContext';

/**
 * Modal holding the form to create a new dataset.
 *
 * @param show should the modal displayed
 * @param handleClose callback function to call when closing the modal
 */
const FormDataset = ({show, handleClose}) => {
    const { setDatasetNames } = useDataSelectorContext();

    const [validated, setValidated] = useState(false);
    const [apiError, setApiError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [name, setName] = useState("");
    const [host, setHost] = useState("");
    const [port, setPort] = useState("");
    const [dbName, setDbName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [provider, setProvider] = useState("SQL_SERVER");

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        setIsSubmitting(true);
        setValidated(false);
        setApiError("");

        fetch("/api/datasets", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                host,
                port,
                dbName,
                provider,
                username,
                password
            })
        })
            .then(response => {
                if (response.status === 409) {
                    throw new Error("Ce dataset existe déjà.");
                } else if (response.status === 400) {
                    setAlertMessage(`Impossible d’ouvrir la base de données "${dbName}" demandée par la connexion. La connexion a échoué.`);
                    setShowAlert(true);
                    throw new Error("");
                }
                setValidated(true);
                setDatasetNames(prevDatasetNames => [...prevDatasetNames, name])
                return response.json();
            })
            .then(() => {
                setIsSubmitting(false);
                handleClose();
            })
            .catch(error => {
                setValidated(false);
                setApiError(error.message);
                setIsSubmitting(false);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Ajout d&apos;un dataset</Modal.Title>
            </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
            {showAlert && (
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            )}
            <Form.Group className="mb-3">
                <Form.Label>Nom</Form.Label>
                <Form.Control value={name} onChange={(e) => setName(e.target.value)} required isInvalid={apiError !== ""}/>
                { apiError !== "" &&
                    <Form.Control.Feedback type="invalid">
                        {apiError}
                    </Form.Control.Feedback>
                }
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Type de la base de données</Form.Label>
                <Form.Select aria-label="Choix du type de base de données" onChange={(e) => setProvider(e.target.value)} required>
                    <option value="SQL_SERVER">SQL Server</option>
                    <option value="POSTGRES">PostgreSQL</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Hôte</Form.Label>
                <Form.Control value={host} onChange={(e) => setHost(e.target.value)} required />
            </Form.Group>

            <Row className="mb-3">
                <Form.Group as={Col}>
                    <Form.Label>Port</Form.Label>
                    <Form.Control type="number" min={1} max={65535} value={port} onChange={(e) => setPort(e.target.value)} required />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Nom de la base de données</Form.Label>
                    <Form.Control value={dbName} onChange={(e) => setDbName(e.target.value)} required />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col}>
                    <Form.Label>Utilisateur</Form.Label>
                    <Form.Control value={username} onChange={(e) => setUsername(e.target.value)} required />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </Form.Group>
            </Row>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-danger" onClick={handleClose} disabled={isSubmitting}>
                Annuler
            </Button>
            <Button variant="success" type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                    <Spinner animation="grow" size="sm" role="status" aria-hidden="true"/>
                ) : (
                    "Ajouter"
                )}
            </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    );
};

export default FormDataset;