import './ResearchConfigForm.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button, ProgressBar, Spinner } from 'react-bootstrap';
import { Stepper } from 'react-form-stepper';

import DropConfig from './common/DropConfig';
import SamplingStep from './sampling/SamplingStep';
import AttributesStep from './fields/AttributesStep';
import DataPreviewStep from './dataPreview/DataPreviewStep';
import { useResearchConfig } from './ResearchConfigContext';
import MatchingStep from './matching/MatchingStep';
import _ from 'lodash';

/**
 * FormSearchConfig is a component for managing search configuration form.
 * It includes steps for sampling, fields, and matching, and displays toast notifications.
 *
 * @returns {JSX.Element} The FormSearchConfig JSX element.
 */
const ResearchConfigForm = () => {
    const {
        researchId,
        activeStep,
        setActiveStep,
        leftSamplerConfiguration,
        rightSamplerConfiguration,
        matchingLeftAttributes,
        matchingRightAttributes,
        handleShow,
        updateConfiguration,
        matchingConfigs
    } = useResearchConfig();
    const navigate = useNavigate();
    const [researching, setResearching] = useState(false);
    const [currentProgress, setCurrentProgress] = useState([0, 0, 0]);

    const getFinalSamplingConfig = (side, algo) => {
        return {
            id: researchId,
            entity: side,
            samplerAlgorithm: {
                type: "SAMPLING",
                ...algo
            }
        };
    };

    /**
     * Save the sampling configuration.
     *
     * @param {Object} leftAlgorithmConfig The configuration of the left entity
     * @param {Object} rightAlgorithmConfig The configuration of the right entity
     */
    const saveSamplingConfig = (leftAlgorithmConfig, rightAlgorithmConfig) => {
        const leftConfig = getFinalSamplingConfig("LEFT", leftAlgorithmConfig);
        const rightConfig = getFinalSamplingConfig("RIGHT", rightAlgorithmConfig);

        updateConfiguration(leftConfig, '/sampler-configuration').then();
        updateConfiguration(rightConfig, '/sampler-configuration').then();
    };

    /**
     * fetching status that return -1 when research end
     * @returns {Promise<boolean>}
     * @param researchIdToFetch
     */
    function fetchResearchStatus(researchIdToFetch) {
        return fetch(`/api/researches/${researchIdToFetch}/status`)
            .then(response => response.json())
            .then(json => {
                const comparisonsDone = json.comparisonsDone;
                const comparisonsTodo = json.comparisonsTodo;
                setCurrentProgress([comparisonsDone  * 100 / comparisonsTodo , comparisonsDone , comparisonsTodo ]);
                return comparisonsTodo  !== -1;
            });
    }

    /**
     * check status every seconds, and navigate when research is done
     * @param researchIdToCheck
     */
    function checkResearchStatus(researchIdToCheck) {
        const intervalId = setInterval(() => {
            fetchResearchStatus(researchIdToCheck)
                .then(shouldContinue => {
                    if (!shouldContinue) {
                        clearInterval(intervalId); // Stop the interval
                        navigate(`/research/${researchIdToCheck}/review`);
                    }

                });
        }, 1000); // 1000 milliseconds = 1 second
    }

    /**
     * Save the matching configuration for the research.
     */
    const saveMatchingConfigs = () => {
        const rates = {
            "Levenshtein distance": 0.6,
            "Jaro Winkler similarity": 0.7,
            "Égalité stricte entiers small": 0.8,
            "Égalité stricte entiers": 0.8,
            "Égalité stricte entiers longs": 0.8,
            "Égalité stricte nombres réels": 0.8,
            "Égalité stricte chaines de caractères": 0.8,
            "Égalité stricte timestamp": 0.8
        };

        const formattedConfigs = matchingConfigs.map(config => {
            const params = config.parameters.length > 0 ? config.parameters : [];

            return {
                leftAttributeId: config.left_attribute_id,
                rightAttributeId: config.right_attribute_id,
                minimalRate: rates[config.algorithmName],
                matchingAlgorithm: {
                    algorithmName: config.algorithmName,
                    type: config.type,
                    parameters: params.map(param => ({
                        name: param.name,
                        type: param.type,
                        value: param.value
                    }))
                }
            };
        });

        return updateConfiguration(formattedConfigs, '/matcher-configuration');
    };

    const steps = [
        {
            label: 'Échantillonnage',
            component: <SamplingStep/>,
            canNext: () => !_.isEmpty(leftSamplerConfiguration) && !_.isEmpty(rightSamplerConfiguration),
            onNext: () => saveSamplingConfig(leftSamplerConfiguration, rightSamplerConfiguration),
        },
        {
            label: 'Prévisualisation des données',
            component: <DataPreviewStep/>,
            canNext: () => true,
            onNext: () => {
            }
        },
        {
            label: 'Sélection des attributs',
            component: <AttributesStep/>,
            canNext: () => matchingLeftAttributes.length !== 0 && matchingRightAttributes.length !== 0,
            onNext: () => {
            }
        },
        {
            label: 'Matching',
            component: <MatchingStep/>,
            canNext: () => matchingConfigs.length !== 0,
            onNext: () => {
                saveMatchingConfigs()
                    .then(() => {
                        setResearching(true);
                        fetch(`/api/researches/${researchId}/execute`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        });
                    }).then(__ => {
                    checkResearchStatus(researchId);
                });
            }
        },
    ];

    /**
     * Returns the component for the current active step.
     *
     * @returns {JSX.Element} The component for the current active step.
     */
    const getSectionComponent = () => {
        const currentStep = steps[activeStep];
        return currentStep ? currentStep.component : <div>No step selected</div>;
    };

    /**
     * Handles navigation to the next step.
     */
    const handleNextStep = () => {
        steps[activeStep].onNext();
        if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        }
    };

    /**
     * Handles navigation to the previous step.
     */
    const handlePreviousStep = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    return (
        <div className="research-config-container">

            <div className="research-config-header">
                <DropConfig/>
                <Stepper
                    steps={steps.map(step => ({label: step.label}))}
                    activeStep={activeStep} className="font-raleway"
                />
            </div>
            <div className="research-config-body">
                {getSectionComponent()}
            </div>
            {researching &&
                <div style={{
                    display: 'block',
                    width: "30%", padding: "1%"
                }}>
                    Recherche effectuée
                    à <b>{parseInt(currentProgress[0])} %</b> ({currentProgress[1]} / {currentProgress[2]} comparaisons)
                    <ProgressBar now={currentProgress[0]}/>
                    {currentProgress[0] === 100 && <span> Sauvegarde en base de données</span>}

                </div>
            }
            <div className="research-config-footer">
                <Button className="m-4" type="button" variant="outline-danger" onClick={handleShow}>
                    Abandonner
                </Button>
                {activeStep > 0
                    && <Button
                        className="m-4"
                        type="button"
                        variant="outline-primary"
                        onClick={handlePreviousStep}
                    >
                        Précédent
                    </Button>
                }
                <Button
                    className="m-4"
                    type="button"
                    variant={activeStep === steps.length - 1 ? 'success' : 'primary'}
                    disabled={!steps[activeStep].canNext() || researching}
                    onClick={handleNextStep}
                >
                    {
                        researching ? <Spinner
                            animation="border"/> : activeStep === steps.length - 1 ? 'Lancer la recherche' : 'Suivant'
                    }
                </Button>
            </div>

        </div>
    );
};

export default ResearchConfigForm;