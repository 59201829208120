import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import { useDataSelectorContext } from './DataSelectorContext';
import ResponseError from '../../../../ResponseError';

const color = {
    enable: {
        controlBackground: '#D6AF70',
        optionBackground: '#A2C0BD',
        optionText: '#FFFFFF',
        optionBorder:'#c7ad83',
        optionHoverBackground: '#B5CDCA',
        menuBackground: '#A2C0BD',
        singleValueText: '#FFFFFF',
        inputText: '#FFFFFF',
        noCustomMessage: '#FFFFFF'
    },
    disable: {
        controlBackground: '#B9A06FFF',
        optionBackground: '#A2C0BD',
        optionText: '#FFFFFF',
        optionHoverBackground: '#B5CDCA',
        menuBackground: '#A2C0BD',
        singleValueText: '#FFFFFF',
        inputText: '#FFFFFF',
        noCustomMessage: '#FFFFFF'
    }
};

/**
 * Generates options based on dataset names.
 *
 * @param {Array} datasetNames Array of dataset names.
 * @returns {Array} Array of options with 'value' and 'label' properties from the dataset.
 */
function generateOptions(datasetNames) {
    return datasetNames.map(datasetName => (
        {value: datasetName, label: datasetName}
    ));
}

/**
 * Component for selecting dataset with a dropdown menu.
 *
 * @returns {JSX.Element} The rendered Dataset component.
 */
function DatasetSelector() {
    const {
        datasetNames, setDatasetNames,
        setSelectedDatasetName,
        setSelectedDataModelNameLeft,
        setSelectedDataModelNameRight
    } = useDataSelectorContext();
    const [isDisabledSelect, setIsDisabledSelect] = useState(false);
    const [datasetSelectorError, setDatasetSelectorError] = useState(null);

    const handleOptionChange = useCallback((event) => {
        setSelectedDatasetName(event.value);

        // Clear previous data model names
        setSelectedDataModelNameLeft(null);
        setSelectedDataModelNameRight(null);
    }, [setSelectedDatasetName, setSelectedDataModelNameLeft, setSelectedDataModelNameRight]);

    const colorScheme = isDisabledSelect ? color.disable : color.enable;

    /**
     * Custom styles for the Select component.
     *
     * @type {Object} Custom style for Select component.
     */
    const customStyles = {
        // Styles for the control (main container)
        control: (base) => ({
            ...base,
            backgroundColor: colorScheme.controlBackground,
            borderRadius: 10,
            borderWidth: 3,
            borderColor: colorScheme.optionBorder,
            boxShadow: "none"
        }),
        // Styles for the options
        option: (base) => ({
            ...base,
            backgroundColor: colorScheme.optionBackground,
            color: colorScheme.optionText,
            '&:hover': {
                backgroundColor: colorScheme.optionHoverBackground
            }
        }),
        // Styles for the menu
        menu: (base) => ({
            ...base,
            backgroundColor: colorScheme.menuBackground
        }),
        // Styles for the selected option
        singleValue: (base) => ({
            ...base,
            color: colorScheme.singleValueText
        }),
        // Styles for the search input
        input: (base) => ({
            ...base,
            color: colorScheme.inputText
        })
    };

    /**
     * Custom them for the Select component.
     *
     * @param theme Custom them.
     * @returns {*&{colors: string}} Custom colors.
     */
    const customTheme = useCallback((theme) => {
        return ({...theme, colors: ""});
    }, []);

    /**
     * Custom message when no options are available.
     *
     * @returns {JSX.Element} JSX Element representing the message.
     */
    const customNoOptionsMessage = useCallback(() => {
        return (
            <div style={{color: colorScheme.noCustomMessage}}>
                Datasets indisponibles
            </div>
        );
    }, [colorScheme]);

    useEffect(() => {
        /**
         * Fetch dataset names.
         *
         * @returns {Promise<void>} dataset names.
         */
        const fetchDatasetNames = async () => {
            try {
                const response = await fetch('/api/datasets');
                if (response.ok) {
                    const json = await response.json();
                    setDatasetNames(json.map(dataset => dataset.name));
                    setIsDisabledSelect(false);
                } else {
                    setDatasetSelectorError(new ResponseError('Echec de l\'extraction des datasets', response));
                    setIsDisabledSelect(true);
                }
            } catch (error) {
                setDatasetSelectorError(error);
                setIsDisabledSelect(true);
            }
        };
        fetchDatasetNames();
    }, [setDatasetNames, setIsDisabledSelect, setDatasetSelectorError]);

    /**
     * Custom placeholder for the dataset selector.
     *
     * @returns {JSX.Element} JSX Element representing the custom placeholder.
     */
    const customPlaceholder = () => {
        if (datasetSelectorError !== null) {
            return (<option color="">{datasetSelectorError.message}</option>);
        }
        return (<span style={{color: 'white'}}>Dataset</span>);
    };

    return (
        <Select
            className="dataSelector dataModelSelector"
            placeholder={customPlaceholder()}
            onChange={handleOptionChange}
            isDisabled={isDisabledSelect}
            isSearchable
            hideSelectedOptions
            options={generateOptions(datasetNames)}
            styles={customStyles}
            theme={customTheme}
            noOptionsMessage={customNoOptionsMessage}
        />
    );
}

export default DatasetSelector;
