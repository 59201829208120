import { useCallback } from 'react';
import { BaseEdge, BezierEdge, useNodes, useStore } from 'reactflow';

import { getEdgeParams } from './utils.js';
import { getSmartEdge } from "@tisoap/react-flow-smart-edge";

/**
 * Custom edge,
 * code from {@link https://reactflow.dev/examples/edges/simple-floating-edges}}
 *
 * Added getSmartEdge from {@link @tisoap/react-flow-smart-edge} to use SmartBezier paths instead of "classical" Bezier paths
 * @param id
 * @param source
 * @param target
 * @param markerEnd
 * @param style
 * @param label
 * @returns {JSX.Element|null}
 * @constructor
 */
function SimpleFloatingEdge({id, source, target, markerEnd, style, label,interactionWidth}) {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
    const nodes = useNodes();
    if (!sourceNode || !targetNode) {
        return null;
    }


    const {sx, sy, tx, ty, sourcePos, targetPos} = getEdgeParams(sourceNode, targetNode);

    const edgePath= getSmartEdge({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
        nodes,
    });

    // If the value returned is null, it means " getSmartEdge" was unable to find
    // a valid path, and you should do something else instead
    if (edgePath === null) {
        return <BezierEdge {...{id, source, target, markerEnd, style, label}} />
    }

    const {svgPathString, edgeCenterX, edgeCenterY} = edgePath;
    return (
        <BaseEdge
            path={svgPathString}
            labelX={edgeCenterX}
            labelY={edgeCenterY}
            label={label}
            labelShowBg
            labelBgStyle={{fill:"#D9D9D9",padding:10}}
            labelBgBorderRadius={5}
            labelStyle={{
                fontSize: 15,
                fontWeight: 700,
            }}
            labelBgPadding={[10, 10]}
            style={style}
            interactionWidth={interactionWidth}
        />
    );
}

export default SimpleFloatingEdge;
