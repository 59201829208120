import { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import './ComparisonsTable.css';
import format from '../../../../../common/format';


/**
 * A React component for displaying a table of comparisons.
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.onRightRecordChange - Callback function to handle changes to the right record.
 * @param {Function} props.onNextRecord - Callback function to handle moving to the next record.
 * @param {Function} props.setNR - Callback function to set the next record flag.
 * @param {Array} props.items - The items to display in the table.
 * @returns {ReactElement} The rendered table of comparisons.
 */
const ComparisonsTable = ({isSelected, items, onRightRecordChange, onNextRecord, setNR}) => {
    const [sortedItems, setSortedItems] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentRightId, setCurrentRightId] = useState();

    useEffect(() => {

        setSortedItems(items)

    }, [items]);

    /**
     * Handles sorting of the list based on a specified property.
     * Toggles between ascending and descending order.
     *
     * @param {string} property The property to sort by (e.g., 'avg', 'max', 'min').
     */
    const handleSort = useCallback((property) => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);

        const sorted = sortedItems.sort((a, b) => {
            const valueA = a[property];
            const valueB = b[property];
            return newOrder === 'asc' ? valueA - valueB : valueB - valueA;
        });

        setSortedItems([...sorted]);
    }, [sortOrder, sortedItems]);

    const handleSortMed = useCallback(() => {
        handleSort('median');
    }, [handleSort]);

    const handleSortMax = useCallback(() => {
        handleSort('max');
    }, [handleSort]);

    const handleSortMin = useCallback(() => {
        handleSort('min');
    }, [handleSort]);

    useEffect(() => {
        if (onNextRecord && isSelected) {
            const updatedItems = sortedItems.filter(item => item.recordId !== currentRightId);
            const nextRightRecord = updatedItems[0]?.recordId
            setSortedItems(updatedItems);
            setCurrentRightId(nextRightRecord);
            onRightRecordChange(nextRightRecord);
            setNR(false);
        }
    }, [sortedItems, onNextRecord, currentRightId, onRightRecordChange, setNR, isSelected]);

    return (
        <div className="scrollable">
            <Table bordered hover>
                <thead>
                <tr className="text-center">
                    <th onClick={handleSortMed}>Médiane</th>
                    <th onClick={handleSortMax}>Maximum</th>
                    <th onClick={handleSortMin}>Minimum</th>
                </tr>
                </thead>
                <tbody>
                {sortedItems.map((elem) => (
                    <tr key={elem.recordId} className={elem.recordId === currentRightId ? 'table-active' : ''}
                        onClick={() => {
                            onRightRecordChange(elem.recordId);
                            setCurrentRightId(elem.recordId);
                        }}
                    >
                        <td>{format(elem.median)}</td>
                        <td>{format(elem.max)}</td>
                        <td>{format(elem.min)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ComparisonsTable;