import { Container, Row } from 'react-bootstrap';
import SamplingAlgorithmForm from './components/SamplingAlgorithmForm';
import { useResearchConfig } from "../ResearchConfigContext";

/**
 * Component for selecting and configuring sampling algorithms for two entities.
 *
 * @returns {JSX.Element} The JSX element representing the main sampling algorithm configuration component.
 */
const SamplingStep = () => {
    const {
        leftEntity,
        rightEntity,
        algorithmsError,
        leftSamplerConfiguration,
        setLeftSamplerConfiguration,
        rightSamplerConfiguration,
        setRightSamplerConfiguration
    } = useResearchConfig();

    return (
        <div>
            <Container>
                <Row className="mt-3">
                    {algorithmsError && <div>{algorithmsError.message}</div>}
                    <SamplingAlgorithmForm
                        entityName={leftEntity.name}
                        samplerConfig={leftSamplerConfiguration}
                        onConfigChange={setLeftSamplerConfiguration}
                    />

                    <SamplingAlgorithmForm
                        entityName={rightEntity.name}
                        samplerConfig={rightSamplerConfiguration}
                        onConfigChange={setRightSamplerConfiguration}
                    />
                </Row>
            </Container>
        </div>
    );
}

export default SamplingStep;