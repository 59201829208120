/**
 * Formats a decimal value as a percentage with two decimal places.
 *
 * @param {number} value The decimal value to be formatted as a percentage.
 * @returns {string} The formatted percentage.
 */
export const format = (value) => {
    const percentage = value * 100;
    return `${percentage.toFixed(2)}%`;
}

export default format;