import { ListGroup } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';
import format from "../../../common/format";

/**
 * Component that represents a single item in the list of matcher configurations.
 *
 * @param {Object} research The research object.
 * @param {Object} matcher The matcher object.
 * @returns {JSX.Element} The rendered component.
 */
const MarcherConfigurationItems = ({research, matcher}) => {
     return (
        <ListGroup.Item>
            <div>
                <ArrowRight /> ({research.leftEntityName}) {matcher.leftAttribute}
                <br/>
                <ArrowRight /> ({research.rightEntityName}) {matcher.rightAttribute}
            </div>
            <ul className="pl-3">
                <li>Algorithme de matching : {matcher.matchingAlgorithm.name}</li>
                <li>Seuil : {format(matcher.minimalRate)}</li>
            </ul>
        </ListGroup.Item>
     );
}

export default MarcherConfigurationItems;