import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FieldToFieldComparison from './components/comparison/FieldToFieldComparison';
import FooterClericalReview from './components/FooterClericalReview';

import './ComparisonDetails.css';
import ComparisonsTab from './components/comparisonsList/ComparisonsTab';

/**
 * Send a request to the server in order to update the given comparison's review status.
 *
 * @param {Number} researchId The id of the research owning the comparison
 * @param {Number} leftRecordId The id of the left record being reviewed
 * @param {Number} rightRecordId The id of the right record being reviewed
 * @param {String} newStatus The new status to apply to the comparison
 */
const updateComparisonStatus = (researchId, leftRecordId, rightRecordId, newStatus) => {
    const queryParams = new URLSearchParams({leftRecordId, rightRecordId});

    fetch(`/api/researches/${researchId}/comparisons?${queryParams}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            state: newStatus
        })
    });
};

export const fetchComparisonsByStatus = (researchId, recordId, status) => {
    const queryParameters = new URLSearchParams({
        recordId,
        status
    });

    return fetch(`/api/researches/${researchId}/comparisons/summaries?${queryParameters}`)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Server returned : ${response.status}`);
            }
            return response.json();
        });
};

/**
 * Generates the JSX element to display the 2nd page of the Clerical Reviews.
 *
 * @returns {JSX.Element} The JSX element representing the 2nd page of the Clerical Reviews.
 */
function ComparisonsDetails() {
    const {researchId} = useParams();
    const [leftRecordId, setLeftRecordId] = useState(-1);
    const [rightRecordId, setRightRecordId] = useState(-1);
    const [allLeftIds, setAllLeftIds] = useState([]);
    const [currentIndexLeft, setCurrentIndexLeft] = useState(-1);

    const [isNextRecordAvailable, setIsNextRecordAvailable] = useState(true);
    const [isPreviousRecordDisabled, setIsPreviousRecordDisabled] = useState(true);
    const [isNextRecordDisabled, setIsNextRecordDisabled] = useState(false);

    useEffect(() => {
        fetch(`/api/researches/${researchId}/samples/LEFT`)
            .then(response => response.json())
            .then(sample => {
                if (sample.length === 0) {
                    return;
                }
                const newLeftRecordId = sample[0];
                const uniqueIds = new Set(sample);
                setAllLeftIds(Array.from(uniqueIds));
                setCurrentIndexLeft(0);
                setLeftRecordId(newLeftRecordId);
            })
    }, [researchId]);

    const invalidateComparison = () => {
        updateComparisonStatus(researchId, leftRecordId, rightRecordId, "INVALIDATE");
        setIsNextRecordAvailable(true);
    };

    const validateComparison = () => {
        updateComparisonStatus(researchId, leftRecordId, rightRecordId, "VALIDATE");
        setIsNextRecordAvailable(true);
    };

    const compareComparison = () => {
        updateComparisonStatus(researchId, leftRecordId, rightRecordId, "TO_REVIEW");
        setIsNextRecordAvailable(true);
    }

    const changeRightRecordId = useCallback((newRightRecordId) => {
        if (newRightRecordId === undefined){
            return
        }
        setRightRecordId(newRightRecordId);
    }, []);

    const checkNavigationAvailability = useCallback((increaseOrDecreaseId) => {
        setIsPreviousRecordDisabled((currentIndexLeft + increaseOrDecreaseId - 1) < 0);
        setIsNextRecordDisabled((currentIndexLeft + increaseOrDecreaseId + 1) >= allLeftIds.length);
    }, [allLeftIds, currentIndexLeft]);

    const handleChangeLeftId = useCallback((increaseOrDecreaseId) => {
        checkNavigationAvailability(increaseOrDecreaseId);
        setLeftRecordId(allLeftIds[currentIndexLeft + increaseOrDecreaseId]);
        setCurrentIndexLeft(prevState => prevState + increaseOrDecreaseId);
        setIsNextRecordAvailable(true);
    }, [allLeftIds, currentIndexLeft, checkNavigationAvailability]);

    return (
        <div className="body-clerical">
            <Container className="font-clerical">
                <Row>
                    <Col className="left-container">
                        {
                            <ComparisonsTab
                                researchId={researchId}
                                recordId={leftRecordId}
                                onRightRecordChange={changeRightRecordId}
                                onNextRecord={isNextRecordAvailable}
                                setNR={setIsNextRecordAvailable}
                            />
                        }
                        {rightRecordId!== -1 && rightRecordId !== undefined   && <FooterClericalReview
                            onInvalidate={invalidateComparison}
                            onValidate={validateComparison}
                            onCompare={compareComparison}
                        />}
                    </Col>
                    <Col xs={8} className="right-container">
                        {
                            (leftRecordId !== -1 ) &&
                            <FieldToFieldComparison
                                researchId={researchId}
                                leftRecordId={leftRecordId}
                                rightRecordId={rightRecordId}
                                onChangeLeftId={handleChangeLeftId}
                                disablePrevious={isPreviousRecordDisabled}
                                disableNext={isNextRecordDisabled}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ComparisonsDetails;