import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ComparisonsTable from "./ComparisonsTable";
import { fetchComparisonsByStatus } from '../../ComparisonsDetails';

/**
 * Custom hook for fetching comparison data based on research ID, record ID, and status.
 * @param {string} researchId - The ID of the research.
 * @param {string} recordId - The ID of the record.
 * @param {string} status - The status of the records to fetch.
 * @returns {Array} The fetched comparison items.
 */
const useFetchComparisons = (researchId, recordId, status) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchComparisonsByStatus(researchId, recordId, status)
            .then(setItems)
            .catch(_ => setItems([]))
    }, [researchId, recordId, status]);

    return items;
};

/**
 * A React component for displaying a tabbed interface for comparing records.
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.researchId - The ID of the research to compare.
 * @param {string} props.recordId - The ID of the record to compare.
 * @param {Function} props.onRightRecordChange - Callback function to handle changes to the right record.
 * @param {Function} props.onNextRecord - Callback function to handle moving to the next record.
 * @param {Function} props.setNR - Callback function to set the next record flag.
 * @returns {ReactElement} The rendered tabbed interface for comparing records.
 */
const ComparisonsTab = ({researchId, recordId, onRightRecordChange, onNextRecord, setNR}) => {
    const [activeTab, setActiveTab] = useState("toCompare");
    const toCompareItems = useFetchComparisons(researchId, recordId, "TO_REVIEW");
    const validatedItems = useFetchComparisons(researchId, recordId, "VALIDATED");
    const invalidatedItems = useFetchComparisons(researchId, recordId, "INVALIDATED");

    const tabs = [
        {key: 'toCompare', title: 'À comparer', items: toCompareItems},
        {key: 'validated', title: 'Validés', items: validatedItems},
        {key: 'invalidated', title: 'Invalidés', items: invalidatedItems},
    ];

    return (
        <div>
            <span className="titleCompo">Liste des enregistrements à comparer</span>
            <Tabs
                defaultActiveKey="toCompare"
                id="justify-tab-example"
                className="mb-3"
                justify
                activeKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
            >
                {
                    tabs.map(({key, title, items}) => (
                    <Tab eventKey={key} title={title} key={key}>
                        <ComparisonsTable
                            leftId={recordId}
                            isSelected={activeTab === key}
                            items={items}
                            onRightRecordChange={onRightRecordChange}
                            onNextRecord={onNextRecord}
                            setNR={setNR}
                        />
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
};

export default ComparisonsTab;
