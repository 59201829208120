/**
 * Renders an arrow icon for indicating sorting direction.
 *
 * @param {string} field The field on which the sorting is applied.
 * @param {string} type The type of sorting: 'asc' for ascending, 'desc' for descending.
 * @param {Array} sortDirection The current sorting direction, represented as an array [currentField, currentType].
 * @returns {JSX.Element | null} The arrow icon JSX element or null if the field is not currently being sorted.
 */
export const RenderArrow = ({ field, type, sortDirection }) => {
    if (sortDirection[0] !== field) {
        return null;
    }

    if (sortDirection[1] === 'asc' && type === 'asc') {
        return <span>&darr;</span>;
    } else if (sortDirection[1] === 'desc' && type === 'desc') {
        return <span>&uarr;</span>;
    }

    return null;
};

export default RenderArrow;