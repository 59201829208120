import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Col, Button, Table, Row } from 'react-bootstrap';

import RenderArrow from '../../common/RenderArrow';
import EntityResearchListGroup from './components/EntityResearchListGroup';
import './EntityResearchHistoryPage.css';

/**
 * Renders a page displaying the research history of an entity.
 *
 * @returns {JSX.Element} The EntityResearchHistoryPage component.
 */
function EntityResearchHistoryPage() {
    const navigate = useNavigate();

    const {entityId} = useParams();
    const [researches, setResearches] = useState([]);
    const [entityName, setEntityName] = useState("");
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortDirection, setSortDirection] = useState(["", ""]);

    /**
     * Navigates back to the previous page.
     */
    const returnBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        const id = parseInt(entityId);

        // Fetch data researches
        fetch('/api/researches')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Server returned : ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // Filtered with research completed for a specific entity
                setResearches(data.filter(element => (element.leftEntityId === id || element.rightEntityId === id) && element.finishedAt !== null));
            })
            .catch(() => setResearches([]));

        // Fetch entity name
        fetch(`/api/entities/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Server returned : ${response.status}`);
                }
                return response.json();
            })
            .then(entity => {
                setEntityName(entity.name);
            })
            .catch(() => setEntityName(""));
    }, [entityId, setResearches, setEntityName]);

    const handleSort = useCallback((property) => {
        // Toggle between ascending and descending order
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);

        // Sorts the data array according to the specified property and order
        const sorted = researches.sort((a, b) => {
            let valueA = null;
            let valueB = null;

            if (property === 'date') {
                valueA = new Date(a.startedAt);
                valueB = new Date(b.startedAt);
            } else if (property === 'link') {
                valueA = a.link ? 1 : 0;
                valueB = b.link ? 1 : 0;
            }

            return newOrder === 'asc' ? valueA - valueB : valueB - valueA;
        });

        // Updates report with sorted items
        setResearches([...sorted]);
        setSortDirection([property, newOrder]);
    }, [sortOrder, researches]);

    /**
     * Sorts researches by date.
     */
    const sortByDate = useCallback(() => {
        handleSort('date');
    }, [handleSort]);

    /**
     * Sorts researches by link.
     */
    const sortByLink = useCallback(() => {
        handleSort('link');
    }, [handleSort]);

    return (
        <Container className="history">
            <Row className="title mb-3">
                <h2>Historique de l&apos;entit&eacute; : {entityName}</h2>
            </Row>
            <Row>
                <Col>
                    {
                        researches.length > 1 &&
                        <div className="button-sort-history">
                            <Button variant="primary" onClick={sortByDate}>
                                Trier par date de début
                                <RenderArrow field="date" type="asc" sortDirection={sortDirection} />
                                <RenderArrow field="date" type="desc" sortDirection={sortDirection} />
                            </Button>
                            <Button variant="primary" onClick={sortByLink}>
                                Trier par lien
                                <RenderArrow field="link" type="asc" sortDirection={sortDirection} />
                                <RenderArrow field="link" type="desc" sortDirection={sortDirection} />
                            </Button>
                        </div>
                    }
                </Col>
            </Row>
            <Row className="entityResearchListPage">
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th>Début de la recherche</th>
                            <th>Fin de la recherche</th>
                            <th>Lien créé</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        researches.length > 0 ?
                            researches.map(research => {
                                return <EntityResearchListGroup research={research} key={research.id} />;
                            })
                            :
                            <tr>
                                <td colSpan={3}>Pas d&apos;historique disponible.</td>
                            </tr>
                    }
                    </tbody>
                </Table>
            </Row>
            <Row className="d-flex flex-row justify-content-center">
                <Button className="w-25" variant="outline-primary" onClick={returnBack}>Retour</Button>
            </Row>
        </Container>
    );
}

export default EntityResearchHistoryPage;
