import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import _ from 'lodash';

import format from '../../../../../common/format';
import './FieldToFieldComparison.css';

/**
 * Generates the JSX element (list of arrays) to display all comparisons of fields.
 *
 * @returns {JSX.Element} The JSX element representing the list of field-to-field comparisons.
 */
function FieldToFieldComparison({researchId, leftRecordId, rightRecordId, onChangeLeftId, disablePrevious, disableNext}) {
    const [comparisons, setComparisons] = useState([]);

    const [selectedAtt, setSelectedAtt] = useState();

    useEffect(() => {
        const queryParameters = new URLSearchParams({leftRecordId, rightRecordId});

        fetch(`/api/researches/${researchId}/comparisons?${queryParameters}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`API returned code: ${response.status_code}`);
                }
                return response.json();
            })
            .then(newComparisons => {
                setComparisons(newComparisons.fieldToFieldComparisons);
                setSelectedAtt(newComparisons.fieldToFieldComparisons[0].leftField);
            })
            .catch(() => setComparisons([]));
    }, [researchId, leftRecordId, rightRecordId]);

    const couples = [];
    const recordRight = new Map();
    const recordLeft = new Map();

    comparisons.forEach(comparison => {
        couples.push({
            leftField: comparison.leftField,
            rightField: comparison.rightField,
            leftValue: comparison.leftValue,
            rightValue: comparison.rightValue,
            score: comparison.score
        });
        recordRight.set(comparison.rightField, comparison.rightValue);
        recordLeft.set(comparison.leftField, comparison.leftValue);
    });
    const leftEntityArray = Array.from(recordLeft);
    const rightEntityArray = Array.from(recordRight);

    const sortCouples = _.orderBy(couples, 'score', 'desc');
    const divSrollableRef = useRef(null);

    const handleChangeSelected = useCallback((e) => {
        if (e.target.parentElement?.attributes[1] !== undefined) {
            setSelectedAtt(e.target.parentElement.attributes[1].value);
        } else {
            setSelectedAtt(e.target.attributes[1].value);
        }
        if (divSrollableRef.current) {
            divSrollableRef.current.scrollLeft = 0;
        }
    }, [setSelectedAtt]);

    const matchingSelectedField = sortCouples.filter(value => value.leftField === selectedAtt);
    /*Entity Left table*/
    const rows =
        <>
            {leftEntityArray
                .filter(value => value[0] === selectedAtt)
                .map(value => {
                    return (
                        <td key={value.toString()} className="headerSelected" data-th={value[0]}
                            onClick={handleChangeSelected} colSpan={2}>
                            <div className="scrollableCell">
                                {value[0]}
                            </div>
                        </td>
                    );
                })}
            {leftEntityArray
                .filter(value => value[0] !== selectedAtt)
                .map(value => {
                    return (
                        <td key={value.toString()} className="headerNotSelected" data-th={value[0]}
                            onClick={handleChangeSelected}>
                            <div className="scrollableCell">
                                {value[0]}
                            </div>
                        </td>
                    );
                })}
        </>;
    const leftValueRows =
        <>
            {leftEntityArray
                .filter(value => value[0] === selectedAtt)
                .map(value => {
                    return (
                        <td key={value.toString()} className="headerSelected" data-th={value[0]}
                            onClick={handleChangeSelected}
                            colSpan={2}>
                            <div className="scrollableCell selectedEntityRight">
                                {value[1]}
                            </div>
                        </td>
                    );
                })}
            {leftEntityArray
                .filter(value => value[0] !== selectedAtt)
                .map(value => {
                    return (
                        <td key={value.toString()} className="headerNotSelected" data-th={value[0]}
                            onClick={handleChangeSelected}>
                            <div className="scrollableCell ">
                                {value[1]}
                            </div>
                        </td>
                    );
                })}
        </>
    ;

    const rowsEntityRight = [];

    for (const element of matchingSelectedField) {
        rowsEntityRight.push(
            <>
                <Table responsive className="styledTable">
                    <tr>
                        {rightEntityArray
                            .filter(value => value[0] === element.rightField)
                            .map(value => {
                                return (
                                    <>
                                        <td className="selectedEntityRight">
                                            <div className={"scrollableCell"}>
                                                {value[0]}
                                            </div>
                                        </td>
                                        <td className="selectedEntityRight" rowSpan={2}>
                                            <div>
                                                {format(element.score)}
                                            </div>
                                        </td>
                                    </>
                                );
                            })}
                        {rightEntityArray
                            .filter(value => value[0] !== element.rightField)
                            .map((value) => {
                                    return (
                                        <td key={value.toString()} className="notSelectedEntityRight">
                                            <div className={"scrollableCell"}>
                                                {value[0]}
                                            </div>
                                        </td>
                                    );
                                }
                            )
                        }
                    </tr>
                    <tr>
                        {rightEntityArray
                            .filter(value => value[0] === element.rightField)
                            .map((value) => {
                                return (
                                    <td key={value.toString()} className="selectedEntityRight">
                                        <div className={"scrollableCell"}>
                                            {value[1]}
                                        </div>
                                    </td>
                                );
                            })}
                        {rightEntityArray
                            .filter(value => value[0] !== element.rightField)
                            .map(value => {
                                return (<td key={value[0]} className="notSelectedEntityRight">
                                        <div className={"scrollableCell"}>
                                            {value[1]}
                                            <br/>
                                        </div>
                                    </td>
                                );
                            })}
                    </tr>
                </Table>
                <br/>
            </>
        );
    }

    const handleNextLeftId = useCallback(() => {
        onChangeLeftId(1);
    }, [onChangeLeftId]);

    const handlePrevLeftId = useCallback(() => {
        onChangeLeftId(-1);
    }, [onChangeLeftId]);

    return (
        <>
            <span className="titleCompo">Comparaisons champs par champs</span>
            <div className="p-2">
                <Button className="me-2" disabled={disablePrevious} onClick={handlePrevLeftId} variant="secondary">
                    &#9664;
                </Button>
                <Button className="me-2" disabled={disableNext} onClick={handleNextLeftId} variant="secondary">
                    &#9654;
                </Button>
            </div>

            <div ref={divSrollableRef}>
                <Table responsive style={{
                    width: `${(leftEntityArray.length + 1) * 300}px`,
                    minWidth: `${(leftEntityArray.length + 1) * 300}px`
                }}>
                    <tr>
                        {rows}
                    </tr>
                    <tr>
                        {leftValueRows}
                    </tr>
                </Table>
            </div>
            <br/>
            <br/>
            <div className="scrollableContainer">
                {rowsEntityRight}
            </div>
        </>
    );
}


export default FieldToFieldComparison;
