import { Table } from 'react-bootstrap';

import { useResearchConfig } from '../../ResearchConfigContext';
import SettingMatchingAlgorithm from './SettingMatchingAlgorithm';

const MatrixMatchingConfiguration = () => {
    const {matchingLeftAttributes, matchingRightAttributes} = useResearchConfig();

    return (
        <Table>
            <thead>
                <tr>
                    <th/>
                    {matchingLeftAttributes.map(attribute => (
                        <th key={attribute.name}>{attribute.name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {matchingRightAttributes.map(rightAttribute => (
                    <Row key={rightAttribute.name} rightAttribute={rightAttribute}
                         matchingLeftAttributes={matchingLeftAttributes} />
                ))}
            </tbody>
        </Table>
    );
}

const Row = ({rightAttribute, matchingLeftAttributes}) => (
    <tr>
        <th>{rightAttribute.name}</th>
        {matchingLeftAttributes.map(leftAttribute => (
            <td key={leftAttribute.name}>
                <SettingMatchingAlgorithm
                    leftEntityAttribute={leftAttribute}
                    rightEntityAttribute={rightAttribute}
                />
            </td>
        ))}
    </tr>
);

export default MatrixMatchingConfiguration;
