import { Button, Modal } from 'react-bootstrap';
import { useCallback, useState } from 'react';
import './formRelationships.css';
import BodyFormRelationships from './BodyFormRelationships';
import ResponseError from '../../../../ResponseError';

/**
 *
 * @returns {JSX.Element} the JSX element to display the popup to create a relationship
 */
function FormRelationships({data, show, setShow, setRetAtt}) {
    const [allValuesSelected, setAllValuesSelected] = useState(false);
    const [values, setValues] = useState({});

    const handleClose = useCallback(() => {
        setShow(false);
        setAllValuesSelected(false);
    }, [setShow]);

    const handleCloseValidate = useCallback( () =>  {
        handleClose()
        if (!allValuesSelected){
            return
        }
        fetch('/api/relationships', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"leftAttributeId": values.att1, "rightAttributeId": values.att2})
        })
            .then((response,reject) => {
                if (response.ok){
                    return response.json()
                }
                return Promise.reject(new ResponseError("fetch POST /relationship failed", reject))})
            .then(value =>setRetAtt(value));
    }, [allValuesSelected,setRetAtt,handleClose,values]);

    const handleSelectChange = useCallback((selectedValues) => {
        const areAllSelected = selectedValues.att1 !== -1 && selectedValues.att2 !== -1;
        setAllValuesSelected(areAllSelected);
        setValues(selectedValues)
    }, []);

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size={"lg"}
                backdrop="static"
                keyboard={false}
                className="formRelPopup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une relation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BodyFormRelationships onSelectChange={handleSelectChange} data={data}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className=" py-2 px-4 rounded"
                        onClick={handleClose}
                        variant="outline-danger"
                    >
                        Annuler
                    </Button>
                    <Button
                        className=" py-2 px-4 rounded"
                        onClick={handleCloseValidate}
                        form="formAddingRelationship"
                        type="submit"
                        disabled={!allValuesSelected}
                    >
                        Ajouter
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default FormRelationships;
