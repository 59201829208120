/**
 * Component displaying stats about a research.
 *
 * @returns {JSX.Element} The rendered React component.
 */
function ResultStat({ stats }) {
    return (
        <div>
            <p>Nombre de comparaisons conservées : {stats.nbKeptComparisons}</p>
            <p>Nombre de comparaisons non-conservées : {stats.nbDismissedComparisons}</p>
            <p>TOTAL de comparaisons : {stats.nbTotalComparisons}</p>
        </div>
    );
}

export default ResultStat;
