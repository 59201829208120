import { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Summary from './components/Summary';
import './ResearchSummary.css';

/**
 * Represents the main result component.
 * This component includes a fixed-page layout with a table container,
 * statistical results, and a button to proceed to Clerical Review.
 *
 * @returns {JSX.Element} The JSX element representing the Result component.
 */
function ResearchSummary({setHasNoComparisons}) {
    const [summary, setSummary] = useState();
    const { researchId } = useParams();

    useEffect(() => {
        fetch(`/api/researches/${researchId}/summary`)
        .then(res => res.json())
        .then(response => setSummary(response));
    }, [researchId]);

    const stats = {
        nbKeptComparisons: summary?.nbKeptComparisons,
        nbDismissedComparisons: summary?.nbDismissedComparisons,
        nbTotalComparisons : summary?.nbTotalComparisons
    }

    /**
     * Renders an alert message indicating that no data comparisons were found.
     *
     * @returns {JSX.Element} The JSX element containing the alert message.
     */
    const renderNoDataAlert = () => {
        setHasNoComparisons(true);
        return (
            <Container>
                <Alert variant="info">Aucune comparaison n&apos;a été trouvée.</Alert>
            </Container>
        );
    }

    return (
        <div>
            {summary?.medianScores < 1 && renderNoDataAlert()}
            {summary !== undefined && <Summary matrixData={summary?.medianScores} stats={stats} />}
        </div>
    );
}

export default ResearchSummary;
