import { Button } from "react-bootstrap";

/**
 * Generates the JSX element for the footer of the Clerical Review.
 *
 * @param {onInvalidate} onInvalidate The callback function to call when clicking the deny button.
 * @param {onValidate} onValidate The callback function to call when clicking the accept button.
 * @returns {JSX.Element} The JSX element representing the footer of the Clerical Review.
 */
function FooterClericalReview({onInvalidate, onValidate}) {
    return (
        <div className="d-flex justify-content-center">
            <Button className="spaceLeft" variant="danger" onClick={onInvalidate}>&#10006;</Button>
            <Button className="spaceRight" variant="success" onClick={onValidate}>&#10004;</Button>
        </div>
    );
}

export default FooterClericalReview;