import { Container } from 'react-bootstrap';
import DynamicMatrix from '../../../../common/DynamicMatrix';
import MatchingConfigSummary from './MatchingConfigSummary';
import ResultStat from './ResultStat';

/**
 * Component to wrap and structure the result content.
 *
 * @param {Object} children The child components to be included.
 * @returns {JSX.Element} The rendered result container.
 */
function Summary({ matrixData, stats }) {
    /**
     * Render the DynamicMatrix component within a container.
     *
     * @returns {JSX.Element} The JSX element containing the DynamicMatrix component.
     */
    const renderDynamicMatrix = () => (
        <div className="table-container-matrix">
            <DynamicMatrix data={matrixData} />
        </div>
    );

    return (
        <Container>
            {matrixData.length > 0 && renderDynamicMatrix()}
            <ResultStat stats={stats} />
            <MatchingConfigSummary />
        </Container>
    );
}

export default Summary;
