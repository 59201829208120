import { useEffect, useState } from 'react';
import { useStore } from 'reactflow';
import ELK from 'elkjs';

/**
 * Layout nodes, using ELK library
 *
 * @param setNodes
 * @param setEdges
 * @param edges current edges
 * @param nodes current nodes
 * @param nodesIds currents nodes IDs
 * @param instance instance of React Flow, to fitView on render
 * @param edgesIds
 * @returns {null}
 * @constructor
 */
function NodeLayouter({setNodes, setEdges, edges, nodes, nodesIds, instance, edgesIds}) {
    const [positioned, setPositioned] = useState(false);

    // fetch react flow state
    const store = useStore();
    // isolate nodes map
    const nodeInternals = store.nodeInternals;
    // flatten nodes map to array
    const flattenedNodes = Array.from(nodeInternals.values());

    useEffect(() => {
        setPositioned(false);
    }, [edgesIds, nodesIds]);

    useEffect( () => {

        // node dimensions are not immediately detected, so we want to wait until they are
        //we check also the last one because when we have 2 datamodels we need to check it
        if (flattenedNodes[0]?.width && flattenedNodes[flattenedNodes.length - 1]?.width) {
            // create elk graph
            const elk = new ELK()
            const elkOptions = {
                'elk.algorithm': 'layered',
                'elk.layered.spacing.nodeNodeBetweenLayers': '100',
                "elk.spacing.nodeNode" : 110,
                "elk.overlapRemoval.maxIterations" : 80,
                'elk.layered.mergeEdges' : false,
                'elk.layered.mergeHierarchyEdges' : false,
                'elk.layered.crossingMinimization.strategy' : 'LAYER_SWEEP',
                'elk.partitioning.activate': true,

            };
            // use elk graph to layout nodes
            const getLayoutedElements = (nodesToProcess, edgesToProcess, options = {}) => {

                const graph = {
                    id: 'root',
                    layoutOptions: options,
                    children: nodesToProcess.map((node) => ({
                        ...node,
                        // Adjust the target and source handle positions based on the layout
                        // direction.
                        targetPosition: 'top',
                        sourcePosition: 'bottom',
                    })),
                    edges: edgesToProcess,
                };
                return elk
                    .layout(graph)
                    .then((layoutedGraph) => ({
                        nodes: layoutedGraph.children.map((node) => ({
                            ...node,
                            // React Flow expects a position property on the node instead of `x`
                            // and `y` fields.
                            position: {x: node.x, y: node.y},
                        })),

                        edges: layoutedGraph.edges,
                    }))
                    .then(value => value)
                    .catch(console.error);
            };

            const layoutingNodes = async () => {

                // if nodes exist and nodes are not positioned
                if (flattenedNodes.length > 0 && !positioned) {
                    const layouted = await getLayoutedElements(flattenedNodes, edges, elkOptions).valueOf();
                    // update react flow state
                    layouted.nodes
                        .filter(value => value.id.startsWith("node-2"))
                        .forEach(value => {
                            value.position.x += 200;
                        });
                    setNodes(layouted.nodes);
                    setEdges(layouted.edges);
                    setPositioned(true);
                    instance.fitView()
                }

            }
            layoutingNodes()

    }
    }, [setNodes, setEdges, edges, nodes, positioned, flattenedNodes, instance]);

    return null;
};

export default NodeLayouter;