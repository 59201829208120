import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { format } from '../../../../common/format';

/**
 * MatchingConfigSummary component fetches matching configurations for a research
 * and displays them using the ListGroup component.
 *
 * @returns {JSX.Element} The rendered MatchingConfigSummary component.
 */
const MatchingConfigSummary = () => {
    const [leftEntity, setLeftEntity] = useState({});
    const [rightEntity, setRightEntity] = useState({});
    const [matchingConfigs, setMatchingConfigs] = useState();
    const {researchId} = useParams();

    const fetchEntity = (entityId, setEntity) => {
        fetch(`/api/entities/${entityId}`)
            .then(response => response.json())
            .then(entity => setEntity(entity))
    }

    useEffect(() => {
        fetch(`/api/researches/${researchId}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Server returned : ${response.status}`);
                }
                return response.json();
            })
            .then(researchData => {
                setMatchingConfigs(researchData)
                fetchEntity(researchData.leftEntityId, setLeftEntity);
                fetchEntity(researchData.rightEntityId, setRightEntity);
            });
    }, [researchId, setLeftEntity, setRightEntity]);

    return (
        <div className="scrollableContainerSummary">
            <h4>Résumé des paramètres de recherche</h4>
            <Table className="fixed-table-summary">
                <thead>
                <tr>
                    <th>(Entité gauche) Attribut <br/> Algorithme échantillonnage</th>
                    <th>(Entité droite) Attribut <br/> Algorithme échantillonnage</th>
                    <th>Seuil</th>
                    <th>Algorithme de matching</th>
                </tr>
                </thead>
                <tbody>
                {matchingConfigs && matchingConfigs.matcherConfigurations.map(elem => (
                    <tr key={`summary-${elem.id}`}>
                        <td>
                            {`(${leftEntity.name}) ${elem.leftAttribute}`}
                            <br/>
                            {`${matchingConfigs.leftEntitySamplerConfiguration.samplingAlgorithm.name}`},
                            taille: {`${matchingConfigs.leftEntitySamplerConfiguration.sampleSize}`}
                        </td>
                        <td>
                            {`(${rightEntity.name}) ${elem.rightAttribute}`}
                            <br/>
                            {`${matchingConfigs.rightEntitySamplerConfiguration.samplingAlgorithm.name}`},
                            taille : {`${matchingConfigs.rightEntitySamplerConfiguration.sampleSize}`}
                        </td>
                        <td>{format(elem.minimalRate)}</td>
                        <td>{elem.matchingAlgorithm.name}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

export default MatchingConfigSummary;