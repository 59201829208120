import { useEffect, useState } from 'react';
import { Alert, Container, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import format from '../../../common/format';
import Summary from '../summary/components/Summary';
import './FinalResult.css';

/**
 * Represents the final result component.
 *
 * This component includes a table container displaying matrix results,
 * statistical information, and buttons to either reject or create a link.
 *
 * @returns {JSX.Element} The rendered final result component.
 */
function FinalResult({setMedianScore}) {
    const { researchId } = useParams();
    const [summary, setSummary] = useState();

    useEffect(() => {
        const queryParams = new URLSearchParams({status: "VALIDATE"});

        fetch(`/api/researches/${researchId}/summary?${queryParams}`)
            .then(response => response.json())
            .then(newSummary => setSummary(newSummary));
    }, [researchId]);

    const stats = {
        nbKeptComparisons: summary?.nbKeptComparisons,
        nbDismissedComparisons: summary?.nbDismissedComparisons,
        nbTotalComparisons : summary?.nbTotalComparisons
    }

    if (summary === undefined) {
        return <Spinner animation="grow" />
    }

    // Calculates the median of an array of median scores.
    const computeMedian = () => {
        const scoresMedian = summary?.medianScores;
        if (!scoresMedian || scoresMedian.length === 0) {
            return 0;
        }

        const sortedscoresMedian = scoresMedian.sort((a, b) => a.score - b.score);
        if (sortedscoresMedian.length % 2 === 1) {
            const medianIndex = Math.floor(sortedscoresMedian.length / 2);
            return sortedscoresMedian[medianIndex].score;
        }

        const lowRankIndex = (sortedscoresMedian.length / 2) - 1;
        const highRankIndex = lowRankIndex + 1;
        return (sortedscoresMedian[lowRankIndex].score + sortedscoresMedian[highRankIndex].score) / 2;
    }

    /**
     * Renders an alert message indicating that no comparisons have been validated,
     * resulting in no overall score for the review.
     *
     * @returns {JSX.Element} The JSX element containing the alert message.
     */
    const renderNoDataAlert = () => (
        <Container>
            <Alert variant="danger">Aucune comparaison n&apos;a été trouvée ou validée. Il n&apos;y a pas de score global pour la revue.</Alert>
        </Container>
    );

    /**
     * Renders the global score container with the computed median score.
     *
     * @returns {JSX.Element} The rendered global score component.
     */
    const renderScoreGlobal = () => {
        setMedianScore(computeMedian());
        return (
            <Container>
                <h4>Le score global est de :
                    <b> {format(computeMedian())}</b>
                </h4>
            </Container>
        );
    }

    return (
        <>
            {summary?.medianScores && summary?.medianScores.length > 0 ? (
                renderScoreGlobal()
            ) : (
                renderNoDataAlert()
            )}
            <br />
            <Summary matrixData={summary?.medianScores} stats={stats} />
        </>
    );
}

export default FinalResult;