import { useEffect, useRef, useState } from 'react';

import SelectorAlgorithms from '../../common/SelectorAlgorithms';
import { useResearchConfig } from '../../ResearchConfigContext';

const SettingMatchingAlgorithm = ({leftEntityAttribute, rightEntityAttribute}) => {
    const {algorithms, matchingConfigs, setMatchingConfigs} = useResearchConfig();
    const [selectedAlgorithm, setSelectedAlgorithm] = useState('');
    const matchingAlgorithms = algorithms.filter(algorithm => algorithm.type === "MATCHING").map(algo => algo.name);
    matchingAlgorithms.push("Pas comparable");

    const updateMatchingConfigsRef = useRef();
    updateMatchingConfigsRef.current = () => {
        const newConfig = {
            left_attribute_id: leftEntityAttribute.id,
            right_attribute_id: rightEntityAttribute.id,
            type: "MATCHING",
            algorithmName: selectedAlgorithm.name,
            parameters: ""
        };

        const existingConfigIndex = matchingConfigs.findIndex(
            conf => conf.left_attribute_id === newConfig.left_attribute_id
                && conf.right_attribute_id === newConfig.right_attribute_id
        );

        if (existingConfigIndex !== -1) {
            const updatedConfigs = [...matchingConfigs];
            updatedConfigs[existingConfigIndex] = newConfig;
            setMatchingConfigs(updatedConfigs);
        } else {
            setMatchingConfigs([...matchingConfigs, newConfig]);
        }
    };

    useEffect(() => {
        if (selectedAlgorithm && selectedAlgorithm.name) {
            updateMatchingConfigsRef.current({});
        }
    }, [selectedAlgorithm]);

    const handleSelectAlgo = (algorithmName) => {
        const chosenAlgorithm = algorithms.find(algorithm => algorithm.name === algorithmName);
        if (chosenAlgorithm) {
            setSelectedAlgorithm(chosenAlgorithm);
            updateMatchingConfigsRef.current({})
        }
    };

    return (
        <SelectorAlgorithms
            currentAlgorithm={selectedAlgorithm}
            algorithms={matchingAlgorithms}
            onChangeAlgorithm={handleSelectAlgo}
        />
    );
};

export default SettingMatchingAlgorithm;