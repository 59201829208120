import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import './Matrix.css';
import format from './format';

/**
 * A dynmaic table displaying data of format {topField: string, leftField: string, score: double}
 *
 * @returns {JSX.Element} The rendered table component.
 */
const DynamicMatrix = ({data}) => {
    const uniqueFields = [...new Set(data.map(elem => elem.rightField))];
    const uniqueLeftFields = [...new Set(data.map(elem => elem.leftField))];
    const {researchId} = useParams();
    const [leftEntity, setLeftEntity] = useState({});
    const [rightEntity, setRightEntity] = useState({});

    // Fetches entity data from the API based on the provided entityId.
    const fetchEntity = (entityId, setEntity) => {
        fetch(`/api/entities/${entityId}`)
            .then(response => response.json())
            .then(entity => setEntity(entity))
    }

    useEffect(() => {
        const id = parseInt(researchId);

        // Fetch data research with specific id
        fetch(`/api/researches/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Server returned : ${response.status}`);
                }
                return response.json();
            })
            .then(researchData => {
                fetchEntity(researchData.leftEntityId, setLeftEntity);
                fetchEntity(researchData.rightEntityId, setRightEntity);
            });
    }, [researchId, setLeftEntity, setRightEntity]);

    return (
        <Table className="fixed-table">
            <thead>
            <tr className="text-center">
                <th>{leftEntity.name} &darr; | {rightEntity.name} &rarr;</th>
                {uniqueFields.map(rightField => (
                    <th className="text-center" key={rightField}>{rightField}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {uniqueLeftFields.map(leftField => (
                <tr key={leftField}>
                    <th>{leftField}</th>
                    {uniqueFields.map(rightField => {
                        const matchingElem = data.find(elem => elem.leftField === leftField && elem.rightField === rightField);
                        return (
                            <td key={rightField}>
                                {matchingElem ? format(matchingElem.score) : 'N/A'}
                            </td>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </Table>
    );
}

export default DynamicMatrix;