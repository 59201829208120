import { createBrowserRouter } from 'react-router-dom';

import MainPage from './pages/Home/MainPage';
import { ResearchConfigProvider } from "./pages/research-configuration/ResearchConfigContext";
import ResearchConfigForm from "./pages/research-configuration/ResearchConfigForm";
import ClericalReview from "./pages/clerical-review/ClericalReview";
import EntityResearchHistoryPage from './pages/ResearchHistory/EntityResearchHistoryPage';
import EntityResearchHistoryDetailPage from './pages/ResearchHistory/EntityResearchHistoryDetailPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />,
    },
    {
        path: "/research/:researchId/review",
        element: <ClericalReview/>
    },
    {
        path: "/research/:researchId/config",
        element: (
            <ResearchConfigProvider>
                <ResearchConfigForm />
            </ResearchConfigProvider>
        )
    },
    {
        path: "/research/entity/:entityId/history",
        element: <EntityResearchHistoryPage />
    },
    {
        path: "/research/:researchId/details",
        element: <EntityResearchHistoryDetailPage />
    }
]);

export default router;