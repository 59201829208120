/**
 * Formats a date string into a localized date and time string.
 *
 * @param {string} dateString The date string to format.
 * @returns {string} The formatted date and time string.
 */
export function FormattedDate({dateString}) {
    const date = new Date(dateString);
    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };
    return date.toLocaleDateString('fr-FR', options);
}

export default FormattedDate;