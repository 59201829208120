import { Card, ListGroup } from 'react-bootstrap';
import SamplingConfigItem from "./SamplingConfigItem";

/**
 * Renders information about sampling configuration.
 *
 * @param {Object} research The research object containing sampling configuration details.
 */
function SamplingConfigurationInfo({ research, leftEntityName, rightEntityName }) {

    const leftEntitySamplingConfig = research.leftEntitySamplerConfiguration
    const rightEntitySamplingConfig = research.rightEntitySamplerConfiguration

    return (
        <Card className="h-100">
            <Card.Body>
                <Card.Title>Configuration d&apos;&eacute;chantillonnage</Card.Title>
                <ListGroup variant="flush">
                    <SamplingConfigItem
                        entityName={leftEntityName}
                        sampleSize={leftEntitySamplingConfig.sampleSize}
                        algorithmName={leftEntitySamplingConfig.samplingAlgorithm.name}
                    />
                    <SamplingConfigItem
                        entityName={rightEntityName}
                        sampleSize={rightEntitySamplingConfig.sampleSize}
                        algorithmName={rightEntitySamplingConfig.samplingAlgorithm.name}
                    />
                </ListGroup>
            </Card.Body>
        </Card>
    );
}

export default SamplingConfigurationInfo;
