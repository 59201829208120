import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const DataSelectorContext = createContext();

/**
 * Provides a context for managing data selector data's within a component tree.
 *
 * @param {ReactNode} children The child elements wrapped by the provider.
 * @returns {JSX.Element} Returns the JSX element representing the provider.
 */
export function DataSelectorProvider({children}) {
  const [selectedDatasetName, setSelectedDatasetName] = useState(null);
  const [selectedDataModelNameLeft, setSelectedDataModelNameLeft] = useState(null);
  const [selectedDataModelNameRight, setSelectedDataModelNameRight] = useState(null);
  const [showAutoRelations, setShowAutoRelations] = useState(false);
  const [datasetNames, setDatasetNames] = useState([]);

  const contextValue = useMemo(() => ({
    selectedDatasetName, setSelectedDatasetName,
    datasetNames, setDatasetNames,
    selectedDataModelNameLeft, setSelectedDataModelNameLeft,
    selectedDataModelNameRight, setSelectedDataModelNameRight,
    showAutoRelations, setShowAutoRelations
  }), [
    selectedDatasetName, setSelectedDatasetName,
    datasetNames, setDatasetNames,
    selectedDataModelNameLeft, setSelectedDataModelNameLeft,
    selectedDataModelNameRight, setSelectedDataModelNameRight,
    showAutoRelations, setShowAutoRelations
  ]);

  //reset them when DS change
  useEffect(() => {
    setSelectedDataModelNameLeft(null);
    setSelectedDataModelNameRight(null);

  }, [selectedDatasetName]);

  return (
    <DataSelectorContext.Provider value={contextValue}>
      {children}
    </DataSelectorContext.Provider>
  );
}

/**
 * Custom hook to use data selector context values.
 */
export const useDataSelectorContext = () => useContext(DataSelectorContext);
