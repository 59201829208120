import { useNavigate } from 'react-router-dom';
import { Calendar } from 'react-bootstrap-icons';

import './EntityResearchListGroup.css';
import FormattedDate from '../FormattedDate';

/**
 * Renders a list group item for displaying entity research details.
 *
 * @param {Object} props The props object.
 * @param {Object} props.research The research object containing research details.
 */
function EntityResearchListGroup({ research }) {
    const navigate = useNavigate();

    /**
     * Redirects to the detailed research page when the component is clicked.
     */
    const showEntityHistoryDetailPage = () => {
        navigate(`/research/${research.id}/details`);
    };

    return (
        <tr onClick={showEntityHistoryDetailPage} className="interactable">
            <td>
                <Calendar /> <FormattedDate dateString={research.startedAt} />
            </td>
            <td>
                <Calendar /> <FormattedDate dateString={research.finishedAt} />
            </td>
            <td>{research.link ? "Oui" : "Non"}</td>
        </tr>
    );
}

export default EntityResearchListGroup;
