import Select from 'react-select';
import { useCallback } from 'react';

import './DataModelSelector.css';

const color = {
    enable: {
        controlBackground: '#FFFFFF',
        optionBorderLeft: 'rgb(231,101,101)',
        optionBorderRight: 'rgb(101,193,231)',
        optionBackground: '#1D4863',
        optionText: '#FFFFFF',
        optionHoverBackground: '#2C678E',
        menuBackground: '#1D4863',
        singleValueText: '#2C678E',
        inputText: '#2C678E',
        noCustomMessage: '#FFFFFF'
    },
    disable: {
        controlBackground: '#D3D3D3',
        optionBorderLeft: '#99A4A3',
        optionBorderRight: '#99A4A3',
        optionBackground: '#99A4A3',
        optionText: '#FFFFFF',
        optionHoverBackground: '#2C678E',
        menuBackground: '#1D4863',
        singleValueText: '#2C678E',
        inputText: '#2C678E',
        noCustomMessage: '#FFFFFF'
    }
};

/**
 * Generates options from data model names.
 *
 * @param {string[]} dataModelNames Array of data model names.
 * @returns {Object[]} Options for Select component.
 */
function generateOptions(dataModelNames) {
    return dataModelNames.map(dataModelName => (
        { value: dataModelName, label: dataModelName }
    ));
}

/**
 * Component for selecting a data model from a list of options.
 *
 * @param {string[]} [dataModelNames=[]] An array of data model names.
 * @param {Function} handleSelectOptionChange Function to handle option change event.
 * @param {boolean} [isDisabledSelect=true] Indicates whether the select input is disabled.
 * @param {boolean} [isControlShouldRenderValue=false] Indicates to control if it should render value.
 * @param {Error|ResponseError|null} [dataModelsSelectorError=null] Error object representing an error in data model selection.
 * @returns {JSX.Element} DataModelSelector component.
 */
function DataModelSelector({
    dataModelNames = [],
    handleSelectOptionChange,
    isDisabledSelect = true,
    isControlShouldRenderValue = false,
    dataModelsSelectorError = null,
    isLeft
}) {

    const colorScheme = isDisabledSelect ? color.disable : color.enable;

    /**
     * Custom styles for the Select component.
     *
     * @type {Object} Custom style for Select component.
     */
    const customStyles = {
        // Styles for the control (main container)
        control: (base) => ({
            ...base,
            backgroundColor: colorScheme.controlBackground,
            borderRadius: 10,
            borderWidth: 3,
            borderColor: isLeft ? colorScheme.optionBorderLeft : colorScheme.optionBorderRight,
            boxShadow: "none"
        }),
        // Styles for the options
        option: (base) => ({
            ...base,
            backgroundColor: colorScheme.optionBackground,
            color: colorScheme.optionText,
            '&:hover': {
                backgroundColor: colorScheme.optionHoverBackground
            }
        }),
        // Styles for the menu
        menu: (base) => ({
            ...base,
            backgroundColor: colorScheme.menuBackground
        }),
        // Styles for the selected option
        singleValue: (base) => ({
            ...base,
            color: colorScheme.singleValueText
        }),
        // Styles for the search input
        input: (base) => ({
            ...base,
            color: colorScheme.inputText
        })
    };

    /**
     * Custom them for the Select component.
     *
     * @param theme Custom them.
     * @returns {*&{colors: string}} Custom colors.
     */
    const customTheme = useCallback((theme) => {
        return ({ ...theme, colors: "" });
    }, []);

    /**
     * Custom message when no options are available.
     *
     * @returns {JSX.Element} JSX Element representing the message.
     */
    const customNoOptionsMessage = useCallback(() => {
        return (
            <div style={{ color: colorScheme.noCustomMessage }}>
                Data Models indisponibles
            </div>
        );
    }, [colorScheme]);

    /**
     * Custom placeholder for the dataset selector.
     *
     * @returns {JSX.Element} JSX Element representing the custom placeholder.
     */
    const customPlaceholder = () => {
        if (dataModelsSelectorError !== null) {
            return (
                <option
                    style={{ color: colorScheme.noCustomMessage }}>
                    {dataModelsSelectorError.message}
                </option>
            );
        }
        return (<span style={{ color: '#A2C0BD' }}>Modèle de données</span>);
    };

    return (
        <Select
            className="dataSelector"
            placeholder={customPlaceholder()}
            onChange={handleSelectOptionChange}
            isDisabled={isDisabledSelect}
            isSearchable
            options={generateOptions(dataModelNames)}
            styles={customStyles}
            theme={customTheme}
            noOptionsMessage={customNoOptionsMessage}
            controlShouldRenderValue={isControlShouldRenderValue}
        />
    );
}

export default DataModelSelector;